// import { Product } from 'pages/Product';
import Client from '../Client.provider';
import {
  iExhibitorListRest,
  iRest,
  iFormExhibitor,
  iExhibitorRest,
} from './Exhibitor.type';
import { iProductExtractRest } from 'services/Product/Product.type';

export interface Ext {
  exhibitor: iExhibitorRest & { logo_url: string };
  products: Array<iProductExtractRest | null>;
}
export interface iExhibitorRepository {
  getExhibitors: () => Promise<iExhibitorListRest[]>;
  getUserExhibitor: (idExhibitor?: string) => Promise<iRest>;
  extractUserExhibitor: (idExhibitor?: string) => Promise<Ext>;
  updateUserExhibitor: (
    form: iFormExhibitor,
    exhibitorId: number
  ) => Promise<iRest>;
  mergeUserExhibitor: (
    type: any,
    value: any,
    exhibitorId: number
  ) => Promise<boolean>;
  lockAllExhibitors: (locked: boolean) => Promise<boolean>;
  visibleAllExhibitors: (visible: boolean) => Promise<boolean>;
  uploadCSV: (form: FormData) => Promise<any>;
}

export class ExhibitorRepository
  extends Client
  implements iExhibitorRepository
{
  public async getExhibitors(): Promise<iExhibitorListRest[]> {
    return await this.restricted()
      .get<iExhibitorListRest[]>(`/user/exhibitors`)
      .then((e) => e.data)
      .catch((error) => {
        throw new Error(
          error.response.data.message ||
            `${error.response.status} | Unknow error...`
        );
      });
  }

  public async getUserExhibitor(idExhibitor: string = ''): Promise<iRest> {
    return await this.restricted()
      .get<iRest>(`/user/exhibitor${idExhibitor ? `/${idExhibitor}` : ''}`)
      .then((e) => e.data)
      .catch((error) => {
        throw new Error(
          error.response.data.message ||
            `${error.response.status} | Unknow error...`
        );
      });
  }

  public async extractUserExhibitor(idExhibitor: string = ''): Promise<Ext> {
    return await this.restricted()
      .get<{
        exhibitor: iExhibitorRest & { logo_url: string };
        products: Array<iProductExtractRest | null>;
      }>(`/user/exhibitor/extract${idExhibitor ? `/${idExhibitor}` : ''}`)
      .then((e) => e.data)
      .catch((error) => {
        throw new Error(
          error.response.data.message ||
            `${error.response.status} | Unknow error...`
        );
      });
  }

  public async updateUserExhibitor(
    form: iFormExhibitor,
    exhibitorId: number
  ): Promise<iRest> {
    return await this.restricted()
      .put<iRest>(`/user/exhibitor/${exhibitorId}`, form)
      .then((e) => e.data)
      .catch((error) => {
        throw new Error(
          error.response.data.message ||
            JSON.stringify(error.response.data)
              .replace(/[{}[\]"]/g, '')
              .replace(/,/g, '<br/>') ||
            `${error.response.status} | Unknow error...`
        );
      });
  }

  public async mergeUserExhibitor(
    type: string,
    value: any,
    exhibitorId: number
  ): Promise<boolean> {
    return await this.restricted()
      .put<iRest>(`/user/exhibitor/merge/${exhibitorId}`, {
        [type]: value,
      })
      .then((e) => !e.data)
      .catch((error) => {
        throw new Error(
          error.response.data.message ||
            JSON.stringify(error.response.data)
              .replace(/[{}[\]"]/g, '')
              .replace(/,/g, '<br/>') ||
            `${error.response.status} | Unknow error...`
        );
      });
  }

  public async lockAllExhibitors(locked: boolean): Promise<boolean> {
    return await this.restricted()
      .put<iRest>(`/user/exhibitors/lock`, {
        locked,
      })
      .then((e) => !!e.data)
      .catch((error) => {
        throw new Error(
          error.response.data.message ||
            JSON.stringify(error.response.data)
              .replace(/[{}[\]"]/g, '')
              .replace(/,/g, '<br/>') ||
            `${error.response.status} | Unknow error...`
        );
      });
  }

  public async visibleAllExhibitors(visibility: boolean): Promise<boolean> {
    return await this.restricted()
      .put<iRest>(`/user/exhibitors/visibility`, {
        visibility,
      })
      .then((e) => !!e.data)
      .catch((error) => {
        throw new Error(
          error.response.data.message ||
            JSON.stringify(error.response.data)
              .replace(/[{}[\]"]/g, '')
              .replace(/,/g, '<br/>') ||
            `${error.response.status} | Unknow error...`
        );
      });
  }

  public async uploadCSV(form: FormData): Promise<any> {
    return await this.restricted()
      .post<any>(`/exhibitor/update/import`, form)
      .then((e) => e.data)
      .catch((error) => {
        throw new Error(
          error.response.data.message ||
            JSON.stringify(error.response.data)
              .replace(/[{}[\]"]/g, '')
              .replace(/,/g, '<br/>') ||
            `${error.response.status} | Unknow error...`
        );
      });
  }
}
