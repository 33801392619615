import Client from '../Client.provider';
import { iCodificationRest } from './Codification.type';

export interface iCodificationRepository {
  get: (codeId: string) => Promise<iCodificationRest[]>;
}

export class CodificationRepository
  extends Client
  implements iCodificationRepository
{
  public async get(codeId: string): Promise<iCodificationRest[]> {
    return await this.restricted()
      .get<iCodificationRest[]>(`/codification/${codeId}`)
      .then((e) => e.data)
      .then((e) => e.sort((a, b) => a.label.localeCompare(b.label)))
      .catch((error) => {
        throw new Error(
          error.response.data.message ||
            JSON.stringify(error.response.data)
              .replace(/[{}[\]"]/g, '')
              .replace(/,/g, '<br/>') ||
            `${error.response.status} | Unknow error...`
        );
      });
  }
}
