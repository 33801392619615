/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { Link } from 'react-router-dom';
import { A } from 'TOMA';
import { iStyle, iColors, iSpacing, iText, iBorder } from 'TOMA/_HOC/withStyle';
import { iIcons } from 'TOMA/Atoms/Icon/Icons.type';

interface iCustomLink extends iStyle, iColors, iSpacing, iBorder, iText {
  children: React.ReactNode;
  to: string;
  disabled?: boolean;
  iconBefore?: iIcons;
  iconAfter?: iIcons;
  preventScrollReset?: boolean;
}

export function CustomLink({
  children,
  to,
  disabled,
  preventScrollReset,
  ...props
}: iCustomLink): JSX.Element {
  if (disabled) return children as React.ReactElement;
  return (
    <A.Link {...props}>
      {(before, after, className) =>
        /^http|^mailto/.test(to) ? (
          <a href={to} target="_blank" rel="noreferrer" className={className}>
            {before}
            {children}
            {after}
          </a>
        ) : (
          <Link
            to={to}
            className={className}
            preventScrollReset={preventScrollReset}
          >
            {before}
            {children}
            {after}
          </Link>
        )
      }
    </A.Link>
  );
}

CustomLink.defaultProps = {
  iconBefore: undefined,
  iconAfter: undefined,
  disabled: undefined,
};
