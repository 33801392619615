import React, { useState, useEffect } from 'react';
import { T, O, M, A } from 'TOMA';
import { CSVLink } from 'react-csv';
import { format } from 'date-fns';
import useApiContext from 'hooks/useApiContext';
import {
  Ext,
  iExhibitorRepository,
} from 'services/Exhibitor/Exhibitor.repository';
import { iExhibitorRest, iRest } from 'services/Exhibitor/Exhibitor.type';
import packageRequest from 'helpers/packageRequest';
import { iProductExtractRest } from 'services/Product/Product.type';
import useRepo from 'hooks/useRepo';

interface Props {
  name?: string;
  children: React.ReactNode;
  ids: number[];
}

function Extraction({ children, name, ids }: Props): JSX.Element {
  const {
    exhibitorRepository,
    productRepository,
    codificationRepository,
    mediaRepository,
  } = useApiContext();
  const [loading, setLoading] = useState<boolean>(false);
  const [progress, setProgress] = useState<number>(0);
  const [data, setData] = useState<string[][] | undefined>(undefined);

  const { response: codifTypeVin } = useRepo(
    async () => await codificationRepository.get('type_vin')
  );
  const { response: codifClassification } = useRepo(
    async () => await codificationRepository.get('classification')
  );
  const { response: codifTypeProduit } = useRepo(
    async () => await codificationRepository.get('type_produit')
  );
  const { response: codifMillesime } = useRepo(
    async () => await codificationRepository.get('millesime')
  );
  const { response: codifTypeCulture } = useRepo(
    async () => await codificationRepository.get('type_culture')
  );
  const { response: codifPrices } = useRepo(
    async () => await codificationRepository.get('prices')
  );
  const { response: codifAppellation } = useRepo(
    async () => await codificationRepository.get('appellation')
  );
  const { response: codifCouleur } = useRepo(
    async () => await codificationRepository.get('couleur')
  );

  const { response: codifDenominationIgp } = useRepo(
    async () => await codificationRepository.get('denomination_igp')
  );
  const { response: codifDenomination } = useRepo(
    async () => await codificationRepository.get('denomination')
  );
  const { response: codifRegion } = useRepo(
    async () => await codificationRepository.get('region')
  );
  const { response: codifCepage } = useRepo(
    async () => await codificationRepository.get('cepage')
  );

  async function processing(): Promise<void> {
    if (loading) return;
    setLoading(true);

    const exhibitorList = await packageRequest<Ext>(
      async (id) => {
        const result = await exhibitorRepository.extractUserExhibitor(id);
        return result;
      },
      ids.map((e) => e.toString()),
      (p) => setProgress(p)
    );

    const dataExhibitor: string[][] = exhibitorList
      .sort((a, b) => a.exhibitor.id - b.exhibitor.id)
      .filter((e) => e.exhibitor.rgpd)
      .map(({ exhibitor, products }) => {
        const p = products.map((product) =>
          product
            ? [
                product.name,
                (product.description_en || '').replace(/\n|\r/g, ' '), // remove line breaks
                (product.description_fr || '').replace(/\n|\r/g, ' '), // remove line breaks,
                '',
                codifClassification?.find(
                  ({ topicCode }) => topicCode === product.classification
                )?.label ?? '',
                `${
                  codifAppellation?.find(
                    ({ topicCode }) => topicCode === product.appellation
                  )?.label ??
                  codifDenomination?.find(
                    ({ topicCode }) => topicCode === product.denomination
                  )?.label ??
                  codifDenominationIgp?.find(
                    ({ topicCode }) => topicCode === product.denomination_igp
                  )?.label ??
                  ''
                }`,
                codifTypeProduit?.find(
                  ({ topicCode }) => topicCode === product.type_produit
                )?.label ?? '',
                codifTypeVin?.find(
                  ({ topicCode }) => topicCode === product.type_vin
                )?.label ?? '',
                codifCouleur?.find(
                  ({ topicCode }) => topicCode === product.couleur
                )?.label ?? '',

                codifMillesime?.find(
                  ({ topicCode }) => topicCode === product.millesime
                )?.label ?? '',
                codifTypeCulture?.find(
                  ({ topicCode }) => topicCode === product.type_culture
                )?.label ?? '',
                '',
                codifPrices?.find(
                  ({ topicCode }) => topicCode === product.price
                )?.label ?? '',
                product.photo_1_url,

                product.cepage
                  .split(';')
                  .map(
                    (tc) =>
                      codifCepage?.find(({ topicCode }) => topicCode === tc)
                        ?.label ?? ''
                  )
                  .join(', '),

                codifRegion?.find(
                  ({ topicCode }) => topicCode === product.region
                )?.label ?? '',
              ]
            : []
        );
        return [
          `${exhibitor.id}`,
          exhibitor.raison_sociale,
          exhibitor.siret,
          exhibitor.nom_societe,
          exhibitor.logo_url,
          (exhibitor.description_en || '').replace(/\n|\r/g, ' '), // remove line breaks,
          (exhibitor.description_fr || '').replace(/\n|\r/g, ' '), // remove line breaks,
          '',
          exhibitor.adresse_postale,
          exhibitor.site_web,
          exhibitor.code_postal,
          exhibitor.ville,
          exhibitor.pays,
          exhibitor.contact_revendeur_firstname,
          exhibitor.contact_revendeur_lastname,
          exhibitor.contact_revendeur_tel,
          exhibitor.contact_revendeur_email,
          exhibitor.locked
            ? 'Transmis'
            : exhibitor.raison_sociale
            ? 'Enregistré'
            : '-',
          `${exhibitor.hall} ${exhibitor.stand}`,
          exhibitor.year,
          ...p.flat(),
        ];
      });

    const nameProducts = [...new Array(10)]
      .map((e, i) => [
        `Nom du produit${i + 1}`,
        `Description EN${i + 1}`,
        `Description FR${i + 1}`,
        `Description JP${i + 1}`,
        `Catégorie${i + 1}`,
        `Appellation / Dénomination${i + 1}`,
        `Type1${i + 1}`,
        `Type2${i + 1}`,
        `Type3${i + 1}`,
        `Millésime${i + 1}`,
        `Type de culture${i + 1}`,
        `Médaille${i + 1}`,
        `Fourchette de prix${i + 1}`,
        `Visuel du produit${i + 1}`,
        `Cépage${i + 1}`,
        `Région${i + 1}`,
      ])
      .flat();

    setData([
      [
        'ID',
        'RAISON SOCIALE',
        'SIRET',
        'ENSEIGNE / MARQUE',
        'LOGO',
        'COMPANY DESCRIPTION EN',
        'COMPANY DESCRIPTION FR',
        'COMPANY DESCRIPTION JP',
        'ADRESSE 1',
        'SITE WEB',
        'CP',
        'VILLE',
        'PAYS',
        'NOM CONTACT',
        'PRÉNOM CONTACT',
        'TÉLÉPHONE CONTACT',
        'EMAIL CONTACT',
        'STATUT',
        'HALL + STAND',
        'ANNEE',
        ...nameProducts,
      ],
      ...dataExhibitor,
    ]);

    setLoading(false);
    setProgress(0);
  }

  if (data) {
    return (
      <CSVLink
        style={{ textDecoration: 'none' }}
        filename={`export${name ? `-${name}` : ''}-${format(
          new Date(),
          'dd-MM-yyyy-HH-mm-ss'
        )}.csv`}
        separator={';'}
        enclosingCharacter={'"'}
        data={data}
        onClick={() => setData(undefined)}
      >
        <T.Block px="40" py="10" bg="primary" borderRadius="5" mb="15">
          <A.Text color="white" textDecoration="none">
            Télécharger
          </A.Text>
        </T.Block>
      </CSVLink>
    );
  }

  return (
    <A.Button
      type="button"
      onClick={() => {
        setLoading(true);
        processing();
      }}
      loading={loading}
      progress={progress}
    >
      {children}
    </A.Button>
  );
}

export default Extraction;
