import React from 'react';

import {
  iBorder,
  iSpacing,
  iStyle,
  iTransform,
  iPosition,
  iColors,
  join,
  withStyle,
} from '../../_HOC/withStyle';
import styles from './Image.module.scss';

export interface iImage
  extends iStyle,
    iSpacing,
    iBorder,
    iTransform,
    iColors,
    iPosition {
  aspectRatio?: React.CSSProperties['aspectRatio'];
  objectFit?: React.CSSProperties['objectFit'];
  src: string;
  alt?: string;
  width?: React.CSSProperties['width'];
  height?: React.CSSProperties['height'];
  absolute?: boolean;
  noSrc?: string;
}

const ImageComp = ({
  aspectRatio,
  objectFit,
  src,
  className,
  alt = '',
  width,
  height,
  absolute,
  noSrc,
}: iImage): JSX.Element => (
  <img
    src={src || noSrc}
    width={width}
    height={height}
    data-absolute={absolute}
    style={{ aspectRatio, objectFit }}
    alt={alt}
    className={join(styles.Image, className)}
  />
);

ImageComp.defaultProps = {
  aspectRatio: undefined,
  objectFit: undefined,
  width: '100%',
  height: undefined,
  absolute: undefined,
  alt: undefined,
  noSrc: undefined,
};

export const Image = withStyle(ImageComp);
