import React, { useState, useEffect } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { CustomLink } from 'components/CustomLink';
import useApiContext from 'hooks/useApiContext';
import routes from 'router/routes';
import { T, O, M, A } from 'TOMA';
import s from './Header.module.scss';
import { eRoles } from 'services/User/User.repository';

function Header(): JSX.Element {
  const navigate = useNavigate();
  const { userRepository } = useApiContext();
  const user = userRepository.getUser();

  return (
    <>
      <T.Block
        bg="white"
        style={{ boxShadow: '0 10px 10px rgba(0,0,0,0.05)' }}
        className={s.Header}
      >
        <T.Container py="20">
          <T.Flex gap="2" halign="space-between" valign="center">
            <T.Block>
              <Link to="/">
                <T.Flex valign={'center'}>
                  <A.Image
                    src="/Republique_Francaise_RVB.png"
                    height="75"
                    width="auto"
                    className={s.Header__logo__RF}
                  />
                </T.Flex>
              </Link>
            </T.Block>
            {user && (
              <T.Col flex="1 0 auto" className={s.Header__desk}>
                <CustomLink to="/" color="black" textDecoration="none">
                  Accueil
                </CustomLink>{' '}
              </T.Col>
            )}
            <T.Flex gap="2" valign={'center'}>
              <p>
                <A.Image src="/taste.png" height="50" width="auto" />
              </p>
              {user ? (
                <A.Button
                  appearance="link"
                  onClick={() => {
                    userRepository.logout().finally(() => {
                      navigate(routes.login.to(), { replace: true });
                    });
                  }}
                  color="black"
                  textDecoration="none"
                >
                  <div className={s.Header__desk}>Déconnexion</div>
                  <div className={s.Header__mob}>
                    <A.Icon icon="box-arrow-right" fontSize="22" />
                  </div>
                </A.Button>
              ) : (
                <>
                  <CustomLink to="/" color="black" textDecoration="none">
                    Connexion
                  </CustomLink>
                  <CustomLink to="/signup" color="black" textDecoration="none">
                    Création de compte
                  </CustomLink>
                </>
              )}
            </T.Flex>
          </T.Flex>
        </T.Container>
      </T.Block>
      {user?.role !== eRoles.ADMIN && (
        <div className={s.Header__encart}>
          <T.Block>
            <T.Container>
              <T.Flex halign="space-between" py="20" gap={4}>
                <T.Col>
                  <p>
                    <A.Image
                      src="/BF_Logo_2lignes_BLC.png"
                      height="64"
                      width="auto"
                      className={s.Header__logo}
                    />
                  </p>
                  <A.Text color="white" mb="10" fontSize="18">
                    <b>PROWEIN DÜSSELDORF 2025</b>
                    <br />
                    Pavillon France Vins, Spiritueux, Bières et Cidres
                  </A.Text>

                  <div className={s.Header__line}></div>

                  <T.Flex gap={1} valign="center" mb="10">
                    <A.Icon icon="calendar2-week-fill" color="white" />
                    <T.Block>
                      <A.Text color="white" fontSize="18">
                        Du 10/03/2024 au 12/03/2024
                      </A.Text>
                    </T.Block>

                    <A.Icon icon="geo-fill" color="white" />
                    <T.Block>
                      <A.Text color="white" fontSize="18">
                        Düsseldorf, Allemagne
                      </A.Text>
                    </T.Block>
                  </T.Flex>
                </T.Col>
              </T.Flex>
            </T.Container>
          </T.Block>
        </div>
      )}
    </>
  );
}

export default Header;
