import React, { useContext } from 'react';
import {
  Control,
  FieldValues,
  useController,
  UseControllerProps,
} from 'react-hook-form';
import { A } from 'TOMA';
import { FormContext } from '../Form';

type iInputNumber<T extends FieldValues> = {
  name: string;
  control: Control<T>;
  min?: number;
  max?: number;
  label?: string;
  placeholder?: string;
  required?: string | boolean;
  disabled?: boolean;
  loading?: boolean;
  format?: (value: number) => number;
  information?: React.ReactNode;
} & UseControllerProps<T>;

export function InputNumber<T extends FieldValues>({
  name,
  min,
  max,
  control,
  label,
  placeholder,
  required,
  disabled,
  information,
  format = (e) => e,
}: iInputNumber<T>): JSX.Element {
  const translation = useContext(FormContext);
  const {
    field: { onChange, onBlur, value, ref },
    fieldState: { isTouched },
    formState: { errors, isSubmitted },
  } = useController({
    name,
    control,
    rules: {
      required: required === true ? translation.required : required,
    },
  });

  const path = name.split('.');
  let key;
  let error: any = errors;
  // eslint-disable-next-line no-cond-assign
  while ((key = path.shift())) {
    if (!Number.isNaN(parseInt(key, 10))) {
      key = parseInt(key, 10);
    }
    if (!error[key]) {
      error = undefined;
      break;
    }
    error = error[key];
  }

  const errorMessage = (isTouched || isSubmitted) && error && error.message;

  return (
    <A.InputNumber
      name={name}
      label={label}
      placeholder={placeholder}
      value={value}
      min={min}
      max={max}
      onChange={(e) => {
        const val = format(parseFloat(e.target.value));
        if (onChange) {
          onChange(val);
        }
      }}
      onBlur={() => {
        if (min && value < min) {
          onChange(min);
        }
        if (max && value > max) {
          onChange(max);
        }
        onBlur();
      }}
      ref={ref}
      required={!!required}
      error={errorMessage}
      disabled={disabled}
      information={information}
    />
  );
}
InputNumber.defaultProps = {
  inputMode: undefined,
  label: undefined,
  min: undefined,
  max: undefined,
  disabled: undefined,
  required: undefined,
  placeholder: undefined,
  information: undefined,
};
