import Client from '../Client.provider';
import { iProductForm, iRest } from './Product.type';

export interface iProductRepository {
  getProduct: (productId?: string) => Promise<iRest>;
  newProduct: (
    form: iProductForm,
    exhibitorId: string | number | undefined
  ) => Promise<iRest>;
  updateProduct: (
    form: iProductForm,
    exhibitorId: string | number | undefined,
    productId: string | number | undefined
  ) => Promise<iRest>;
}

export class ProductRepository extends Client implements iProductRepository {
  public async getProduct(productId?: string): Promise<iRest> {
    if (!productId) return { product: null };
    return await this.restricted()
      .get<iRest>(`/user/product/${productId}`)
      .then((e) => e.data)
      .catch((error) => {
        throw new Error(
          error.response.data.message ||
            `${error.response.status} | Unknow error...`
        );
      });
  }

  public async newProduct(
    form: iProductForm,
    exhibitorId: string | number = ''
  ): Promise<iRest> {
    return await this.restricted()
      .post<iRest>(`/user/exhibitor/${exhibitorId}/product`, form)
      .then((e) => e.data)
      .catch((error) => {
        throw new Error(
          error.response.data.message ||
            JSON.stringify(error.response.data)
              .replace(/[{}[\]"]/g, '')
              .replace(/,/g, '<br/>') ||
            `${error.response.status} | Unknow error...`
        );
      });
  }

  public async updateProduct(
    form: iProductForm,
    exhibitorId: string | number = '',
    productId: string | number = ''
  ): Promise<iRest> {
    return await this.restricted()
      .put<iRest>(`/user/exhibitor/${exhibitorId}/product/${productId}`, form)
      .then((e) => e.data)
      .catch((error) => {
        throw new Error(
          error.response.data.message ||
            JSON.stringify(error.response.data)
              .replace(/[{}[\]"]/g, '')
              .replace(/,/g, '<br/>') ||
            `${error.response.status} | Unknow error...`
        );
      });
  }
}
