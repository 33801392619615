import { Alert } from './Alert';
import { Button } from './Button';
import { Circle } from './Circle';
import { Icon } from './Icon';
import { InnerHTML } from './InnerHTML';
import { InputAutocomplete } from './InputAutocomplete';
import { InputCheckbox } from './InputCheckbox';
import { InputDate } from './InputDate';
import { InputFile } from './InputFile';
import { InputMedias } from './InputMedias';
import { InputRadio } from './InputRadio';
import { InputText } from './InputText';
import { InputNumber } from './InputNumber';
import { InputTextarea } from './InputTextarea';
import { InputSelect } from './InputSelect';
import { InputSelect2 } from './InputSelect2';
import { InputSlider } from './InputSlider';
import { InputSwitch } from './InputSwitch';
import { InputPhone } from './InputPhone';
import { Line } from './Line';
import { Link } from './Link';
import { Loading } from './Loading';
import { Text } from './Text';
import { Title } from './Title';
import { Image } from './Image';
import { Popover } from './Popover';

export const A = {
  Alert,
  Button,
  Circle,
  Icon,
  Image,
  InnerHTML,
  InputAutocomplete,
  InputCheckbox,
  InputDate,
  InputFile,
  InputMedias,
  InputRadio,
  InputTextarea,
  InputText,
  InputNumber,
  InputSelect,
  InputSelect2,
  InputSlider,
  InputSwitch,
  InputPhone,
  Line,
  Link,
  Loading,
  Text,
  Title,
  Popover,
};
