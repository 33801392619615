import React, { useState, useEffect } from 'react';

function useRepo<T = any>(
  repo: () => Promise<T>,
  deps: any[] | undefined = []
): {
  response: T | undefined;
  error: React.ReactNode;
  loading: boolean;
  reload: () => void;
  setResponse: React.Dispatch<React.SetStateAction<T | undefined>>;
} {
  const [response, setResponse] = useState<T>();
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<React.ReactNode>(null);

  function reload(): any {
    setError(null);
    setLoading(true);
    repo()
      .then(setResponse)
      .catch((err) => {
        console.error(err);
        const message =
          err?.response?.data?.body.message ||
          err?.message ||
          'Erreur inconnue';
        setError(<div>{message}</div>);
      })
      .finally(() => {
        setLoading(false);
      });
  }

  useEffect(() => {
    repo()
      .then(setResponse)
      .catch((err) => {
        console.error(err);
        const message =
          err?.response?.data?.body.message ||
          err?.message ||
          'Erreur inconnue';

        setError(<div>{message}</div>);
      })
      .finally(() => {
        setLoading(false);
      });
  }, deps);

  return { response, loading, error, reload, setResponse };
}

export default useRepo;
