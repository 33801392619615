import React, { useState } from 'react';
import { T, O, M, A } from 'TOMA';
import Form, { InputText, InputTextarea } from 'form';

export interface iForm {
  list: string;
  topic: string;
}

function Home(): JSX.Element {
  const [query, setQuery] = useState<string>('');
  function handleSubmit(data: iForm): void {
    const list = data.list
      .trim()
      .split(/\n/g)
      .map((e) => e.trim());
    const query = list.map((item: string) => {
      const constante = item
        .replace(/ /gim, '_')
        .replace(/a|Å|à|â|ä/gim, 'a')
        .replace(/e|é|è|ê|ë/gim, 'e')
        .replace(/î|ï|i/gim, 'i')
        .replace(/o|ô|ö/gim, 'o')
        .replace(/u|ù|û|ü/gim, 'u')
        .replace(/ç/gim, 'c')
        .replace(/-|’|'|"|`/gim, '_')
        .replace(/,|%|€|\/|\(|\)|>/gim, '')
        .replace(/_+/gim, '_')
        .toUpperCase();
      return `('${data.topic}', '${constante}', '${
        data.topic
      }:${constante}', '${item.replace(/'/gim, "\\'")}')`;
    });

    setQuery(`
    INSERT INTO \`codifications\` (\`topic\`, \`code\`, \`topicCode\`, \`label\`)
    VALUES\n
    ${query.join(',\n')};`);
  }

  return (
    <T.Container py="50">
      <Form<iForm> onSubmit={handleSubmit}>
        {({ control }) => (
          <>
            <InputTextarea
              label="List :"
              name="list"
              control={control}
              rows={20}
            ></InputTextarea>
            <InputText
              type="text"
              label="Topic :"
              name="topic"
              control={control}
            />
            <A.Button type="submit">Submit</A.Button>
          </>
        )}
      </Form>
      <pre>{query}</pre>
    </T.Container>
  );
}

export default Home;
