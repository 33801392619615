import Form from './Form';

export default Form;
export * from './Form';
export * from './InputAutocomplete';
export * from './InputCheckbox';
export * from './InputFile';
export * from './InputMedias';
export * from './InputRadio';
export * from './InputText';
export * from './InputNumber';
export * from './InputTextarea';
export * from './InputSelect';
export * from './InputSelect2';
export * from './InputSlider';
export * from './InputPhone';
