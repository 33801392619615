import React from 'react';
import styles from './Loading.module.scss';
import { T, A } from '../..';

export function Loading({
  loading,
  error,
  children,
  fullWiewport,
  fullHeight,
  overlay,
}: {
  loading: boolean;
  error?: React.ReactNode;
  children?: React.ReactNode;
  fullWiewport?: boolean;
  fullHeight?: boolean;
  overlay?: boolean;
}): JSX.Element {
  if (error) {
    return (
      <T.Container mw="medium">
        <A.Alert side="⚠️" alert="error">
          {error}
        </A.Alert>
      </T.Container>
    );
  }

  let height: '75vh' | '100' | undefined;
  if (fullWiewport) height = '75vh';
  if (fullHeight) height = '100';

  if (loading && !overlay) {
    return (
      <T.Container>
        <T.Flex valign="center" h={height}>
          <A.Loading color="secondary" />
        </T.Flex>
      </T.Container>
    );
  }

  return (
    <div className={styles.Loading}>
      {loading && overlay && (
        <div className={styles.Loading__loader}>
          <T.Container>
            <T.Flex valign="center" h={fullHeight ? '75vh' : undefined}>
              <A.Loading color="secondary" />
            </T.Flex>
          </T.Container>
        </div>
      )}
      {children}
    </div>
  );
}

Loading.defaultProps = {
  children: undefined,
  fullWiewport: undefined,
  fullHeight: undefined,
  overlay: undefined,
};
