import React, { useState } from 'react';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import useApiContext from 'hooks/useApiContext';
import Form, { InputText } from 'form';
import { T, O, M, A } from 'TOMA';

export interface iFormForgotPassword {
  email: string;
}

function ForgotPassword(): JSX.Element {
  const { userRepository } = useApiContext();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string>();
  const [success, setSuccess] = useState<string | undefined>();

  const schema = yup.object({
    email: yup
      .string()
      .email('Please enter a valid email')
      .required('This field is required'),
  });

  function handleSubmit({ email }: iFormForgotPassword): void {
    setLoading(true);
    userRepository
      .forgotPassword(email)
      .then(() => {
        setSuccess(
          'Vous allez recevoir un email pour changer votre mot de passe.'
        );
      })
      .catch((err: Error) => {
        setError(err.message);
      })
      .finally(() => setLoading(false));
  }

  return (
    <T.Container py="50">
      <T.Block pb="50">
        <A.Title as="h1" fontSize="48" textAlign="center" fw="black">
          Mot de passe oublié
        </A.Title>
      </T.Block>
      <T.Block bg="grey-10" borderRadius="5" mw="medium" mx="auto" p="80">
        {!success && (
          <Form<iFormForgotPassword>
            onSubmit={async (data) => handleSubmit(data)}
            resolver={yupResolver(schema)}
          >
            {({ control }) => (
              <div>
                <T.Block mb="40">
                  <InputText
                    label="Email"
                    name="email"
                    type="text"
                    control={control}
                    required
                  />
                </T.Block>

                <A.Alert alert="error" side="⚠️" mb="30">
                  {error && <A.InnerHTML html={error} />}
                </A.Alert>

                <A.Button type="submit" loading={loading} center>
                  Envoyer
                </A.Button>
              </div>
            )}
          </Form>
        )}

        {success && (
          <>
            <A.Text fontSize="48" textAlign="center" mb="30">
              🎉
            </A.Text>
            <A.Text fontSize="18" textAlign="center" fw="bold">
              {success}
            </A.Text>
          </>
        )}
      </T.Block>
    </T.Container>
  );
}

export default ForgotPassword;
