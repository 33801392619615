import axios, { AxiosInstance } from 'axios';

export interface Auth {
  token: string;
  token_type: string;
  expires_in: number;
  expires_at: number;
}

export default abstract class Client {
  private static _auth: Auth | null = null;

  private readonly config: Record<string, string> = {
    baseURL: `${process.env.REACT_APP_API_URL ?? ''}`,
  };

  protected get auth(): Auth | null {
    const auth = localStorage.getItem('_auth');

    try {
      if (auth) {
        Client._auth = JSON.parse(auth);
        return Client._auth;
      }
    } catch (e) {
      console.warn('Erreur lors du JSON.parse de currentAccount.', auth);
      localStorage.removeItem('_auth');
      Client._auth = null;
    }

    return null;
  }

  protected set auth(auth: Auth | null) {
    Client._auth = auth;
    if (auth) localStorage.setItem('_auth', JSON.stringify(auth));
    else localStorage.removeItem('_auth');
  }

  protected isTokenValid(): boolean {
    if (!this.auth) return false;
    return this.auth.expires_at * 1000 > Date.now();
  }

  protected public(): AxiosInstance {
    return axios.create(this.config);
  }

  protected restricted(): AxiosInstance {
    const axiosInstance = axios.create(this.config);

    axiosInstance.interceptors.request.use(async (config) => {
      return {
        ...config,
        headers: {
          ...config.headers,
          Authorization: `Bearer ${this.auth?.token ?? ''}`,
        },
      };
    });

    axiosInstance.interceptors.response.use(
      (response) => response,
      async (err) => {
        if ([401].includes(err.response.status)) {
          this.auth = null;
          document.dispatchEvent(new CustomEvent('gohome'));
        }
        return await Promise.reject(err);
      }
    );

    return axiosInstance;
  }
}
