/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable n/no-callback-literal */
export default class Animation {
  duration: number = 1000;

  fromTo: [number, number];

  easing: 'linear' | 'easeIn' | 'easeOut' | 'easeInOut';

  power: number;

  private stopped: boolean = false;

  private readonly date: number = 0;

  constructor() {
    this.duration = 1000;
    this.fromTo = [0, 1];
    this.easing = 'easeInOut';
    this.power = 4;
  }

  start(
    duration: number,
    fromTo: [number, number],
    callback: (
      x: number,
      {
        ease,
        isStart,
        isEnd,
      }: { ease: number; isStart: boolean; isEnd: boolean }
    ) => void
  ): any {
    this.duration = duration;
    this.fromTo = fromTo;

    const date: number = Date.now();
    const power = 4;
    let isStart = true;
    let isEnd = false;

    const update = (newAnimation?: boolean) => {
      if (newAnimation) this.stopped = false;
      const newDate: number = Date.now();
      const timePassed = newDate - date;
      let progress = timePassed / duration;
      let ease = 0;

      if (progress > 1) progress = 1;
      if (progress === 1) isEnd = true;

      if (this.easing === 'linear') {
        ease = progress;
      } else if (this.easing === 'easeIn') {
        ease = progress ** power;
      } else if (this.easing === 'easeOut') {
        ease = 1 - (1 - progress) ** power;
      } else if (this.easing === 'easeInOut') {
        if (progress <= 0.5) ease = (2 * progress) ** power / 2;
        else ease = (2 - (2 * (1 - progress)) ** power) / 2;
      }

      callback(ease * (fromTo[1] - fromTo[0]) + fromTo[0], {
        ease,
        isStart,
        isEnd,
      });

      if (!this.stopped && progress !== 1)
        requestAnimationFrame(() => update());
      isStart = false;
    };

    requestAnimationFrame(() => update(true));
  }

  stop() {
    this.stopped = true;
    return this;
  }
}
