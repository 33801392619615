import React, { ReactNode } from 'react';
import {
  iBorder,
  iColors,
  iSizing,
  iSpacing,
  iStyle,
  join,
  withStyle,
} from '../../_HOC/withStyle';
import styles from './Flex.module.scss';

interface iFlex extends iStyle, iSpacing, iColors, iSizing, iBorder {
  children: ReactNode;
  direction?: React.CSSProperties['flexDirection'];
  valign?: React.CSSProperties['alignItems'];
  halign?: React.CSSProperties['justifyContent'];
  flexGrow?: React.CSSProperties['flexGrow'];
  flexShrink?: React.CSSProperties['flexShrink'];
  flexBasis?: React.CSSProperties['flexBasis'];
  gap?: `${number}` | number;
  wrap?: boolean;
}

function FlexComp({
  children,
  direction,
  valign,
  halign,
  gap,
  wrap,
  flexGrow,
  flexShrink,
  flexBasis,
  className,
}: iFlex): JSX.Element {
  let style: React.CSSProperties = {};
  if (direction) style.flexDirection = direction;
  if (halign) style.justifyContent = halign;
  if (valign) style.alignItems = valign;
  if (gap) style.gap = `calc(${gap} * var(--unit))`;
  if (wrap) style.flexWrap = 'wrap';

  if (flexGrow) style.flexGrow = flexGrow;
  if (flexShrink) style.flexShrink = flexShrink;
  if (flexBasis) style.flexBasis = flexBasis;

  if (wrap && gap) {
    style.gap = '0';
    style.marginLeft = `calc((${gap} * var(--unit)) / -2)`;
    style.marginRight = `calc((${gap} * var(--unit)) / -2)`;
    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
    style = {
      ...style,
      '--flexGap': `${gap} * var(--unit)`,
    } as React.CSSProperties;
  }
  return (
    <div
      className={join(styles.Flex, className)}
      style={style}
      data-gap={wrap && gap}
    >
      {children}
    </div>
  );
}

FlexComp.defaultProps = {
  direction: undefined,
  valign: undefined,
  halign: undefined,
  gap: undefined,
  flexGrow: undefined,
  flexShrink: undefined,
  flexBasis: undefined,
  wrap: undefined,
};

export const Flex = withStyle(FlexComp);
