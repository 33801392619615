import Client from '../Client.provider';
import { iPreferenceRest } from './Preference.type';

export interface iPreferenceRepository {
  get: () => Promise<iPreferenceRest[]>;
  find: (key: string) => Promise<string>;
  update: (key: string, value: string) => Promise<string>;
}

export class PreferenceRepository
  extends Client
  implements iPreferenceRepository
{
  public async get(): Promise<iPreferenceRest[]> {
    return await this.restricted()
      .get<iPreferenceRest[]>(`/preference`)
      .then((e) => e.data)
      .catch((error) => {
        throw new Error(
          error.response.data.message ||
            JSON.stringify(error.response.data)
              .replace(/[{}[\]"]/g, '')
              .replace(/,/g, '<br/>') ||
            `${error.response.status} | Unknow error...`
        );
      });
  }

  public async find(key: string): Promise<string> {
    return await this.restricted()
      .get<string>(`/preference/${key}`)
      .then((e) => e.data)
      .catch((error) => {
        throw new Error(
          error.response.data.message ||
            JSON.stringify(error.response.data)
              .replace(/[{}[\]"]/g, '')
              .replace(/,/g, '<br/>') ||
            `${error.response.status} | Unknow error...`
        );
      });
  }

  public async update(key: string, value: string): Promise<string> {
    return await this.restricted()
      .put<string>(`/preference/${key}`, { value })
      .then((e) => e.data)
      .catch((error) => {
        throw new Error(
          error.response.data.message ||
            JSON.stringify(error.response.data)
              .replace(/[{}[\]"]/g, '')
              .replace(/,/g, '<br/>') ||
            `${error.response.status} | Unknow error...`
        );
      });
  }
}
