import React from 'react';
import {
  iBorder,
  iColors,
  iSpacing,
  iStyle,
  iText,
  join,
  withStyle,
} from '../../_HOC/withStyle';
import styles from './Circle.module.scss';

export interface iCircle extends iStyle, iColors, iSpacing, iText, iBorder {
  children: React.ReactNode;
  size: 'small' | 'medium';
}

export const Circle = withStyle(({ children, className, size }: iCircle) => {
  if (!children) return null;
  return (
    <div className={join(className, styles.Circle, styles[size])}>
      {children}
    </div>
  );
});
