import { Block } from './Block';
import { BlockResponsive } from './BlockResponsive';
import { Col } from './Col';
import { Container } from './Container';
import { Flex } from './Flex';

export const T = {
  Block,
  BlockResponsive,
  Col,
  Container,
  Flex,
};
