import Client from '../Client.provider';

export interface iConfirmationRepository {
  confirm: (token: string | undefined) => Promise<string>;
}

export class ConfirmationRepository
  extends Client
  implements iConfirmationRepository
{
  public async confirm(token: string | undefined = ''): Promise<string> {
    return await this.public()
      .post<string>(`/confirmation`, { token })
      .then((e) => e.data)
      .catch((error) => {
        throw new Error(
          error.response.data.message ||
            JSON.stringify(error.response.data)
              .replace(/[{}[\]"]/g, '')
              .replace(/,/g, '<br/>') ||
            `${error.response.status} | Unknow error...`
        );
      });
  }
}
