import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import useApiContext from 'hooks/useApiContext';
import useRepo from 'hooks/useRepo';
import { T, O, M, A } from 'TOMA';
import { CustomLink } from 'components/CustomLink';

function Confirmation(): JSX.Element {
  const { confirmationId } = useParams();
  const { confirmationRepository } = useApiContext();
  const {
    response: confirmation,
    loading,
    error,
  } = useRepo(async () => await confirmationRepository.confirm(confirmationId));

  return (
    <T.Container py="50">
      <M.Loading loading={loading} error={error} fullWiewport>
        <T.Flex direction="column" valign="center">
          <A.Text mb="20">Votre compte a été activé</A.Text>
          <CustomLink
            to="/"
            py="15"
            px="30"
            textDecoration="none"
            bg="primary"
            color="white"
            borderRadius="5"
          >
            Connectez-vous
          </CustomLink>
        </T.Flex>
      </M.Loading>
    </T.Container>
  );
}

export default Confirmation;
