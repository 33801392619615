import useApiContext from 'hooks/useApiContext';
import useRepo from 'hooks/useRepo';
import React, { useEffect } from 'react';
import { ModalProvider } from 'contexts/ModalContext';
import { useNavigate } from 'react-router-dom';
import { M } from 'TOMA';
import Toma from 'TOMA/Toma';
import { ApiProvider } from './ApiContext';
import AppRouter from './router/AppRoutes';
import routes from 'router/routes';

function App(): JSX.Element {
  const { userRepository } = useApiContext();
  const navigate = useNavigate();

  const { loading } = useRepo(async () => await userRepository.reconnectUser());

  useEffect(() => {
    document.addEventListener(
      'gohome',
      () => {
        const { pathname } = window.location;
        const pathRef = pathname === '/' ? '' : `#${pathname}`;
        if (!pathname.startsWith('/login')) {
          navigate(`${routes.login.to()}${window.location.search}${pathRef}`, {
            replace: true,
          });
        }
      },
      false
    );
  }, []);

  return (
    <M.Loading loading={loading} fullWiewport>
      <Toma />
      <ModalProvider>
        <ApiProvider>
          <AppRouter />
        </ApiProvider>
      </ModalProvider>
    </M.Loading>
  );
}

export default App;
