import React from 'react';
import { A } from '..';
import styles from './InputFile.module.scss';

export interface iInputFile {
  name: string;
  id?: string;
  disabled?: boolean;
  required?: boolean;
  label?: string;
  multiple?: boolean;
  error?: React.ReactNode;
  success?: React.ReactNode;
  onChange?: (e: React.FocusEvent<HTMLInputElement>) => void;
  onRemove?: () => void;
  loading?: boolean;
  information?: React.ReactNode;
  fileName?: string;
  accept?: string;
  onlyButton?: boolean;
  buttonLabel?: string;
}

const InputFile = React.forwardRef(function field(
  {
    name,
    id,
    label,
    disabled,
    multiple,
    loading,
    onChange,
    onRemove,
    fileName,
    error,
    success,
    information,
    required,
    accept = 'image/png, image/jpeg, image/jpg',
    onlyButton,
    buttonLabel = 'Choose file',
  }: iInputFile,
  ref: React.ForwardedRef<HTMLInputElement>
) {
  return (
    <div className={styles.InputFile}>
      {label && (
        <label
          htmlFor={id ?? name}
          className={styles.InputFile__label}
          data-error={!!error}
          data-disabled={disabled}
        >
          {label}
          {required && <i data-asterix>*</i>}
        </label>
      )}
      {information && (
        <A.Text
          as="div"
          mb="10"
          mt="-3"
          fontSize="14"
          color={disabled ? 'grey-20' : 'grey-50'}
        >
          {information}
        </A.Text>
      )}
      <label
        htmlFor={id ?? name}
        className={styles.InputFile__group}
        data-error={!!error}
        data-disabled={disabled}
        data-onlyButton={!!onlyButton}
      >
        {!onlyButton && (
          <div
            className={styles.InputFile__fileName}
            data-placeholder={!fileName}
            data-error={!!error}
            data-disabled={disabled}
          >
            {fileName ?? 'Aucun fichier sélectionné'}
          </div>
        )}
        <input
          name={name}
          type="file"
          id={id ?? name}
          disabled={disabled}
          multiple={multiple}
          onChange={onChange}
          accept={accept}
          className={styles.InputFile__field}
          ref={ref}
        />
        {fileName && (
          <div
            className={styles.InputFile__trash}
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              if (onRemove) onRemove();
            }}
          >
            <A.Icon icon="trash" />
          </div>
        )}
        <div
          className={styles.InputFile__button}
          data-button-disabled={disabled}
        >
          {loading ? <A.Loading color="white" width={20} /> : buttonLabel}
        </div>
      </label>

      <A.Text as="div" mt="5" fontSize="14" color="success">
        {success}
      </A.Text>
      <A.Text as="div" mt="5" fontSize="14" color="alert">
        {error}
      </A.Text>
    </div>
  );
});

InputFile.defaultProps = {
  required: undefined,
  multiple: false,
  disabled: undefined,
};

export { InputFile };
