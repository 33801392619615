import React, { useEffect } from 'react';
import { M } from 'TOMA';

const ModalContext = React.createContext<{
  setModalContent: (content: React.ReactNode) => void;
  setModalVisible: (visible: boolean) => void;
}>({
  setModalContent: () => {},
  setModalVisible: () => {},
});

function ModalProvider({
  children,
}: {
  children: React.ReactNode;
}): JSX.Element {
  const [content, setModalContent] = React.useState<React.ReactNode>(false);
  const [visible, setModalVisible] = React.useState<boolean>(false);

  const value = React.useMemo(
    () => ({ setModalContent, setModalVisible }),
    [setModalContent, setModalVisible]
  );

  useEffect(() => {
    setModalVisible(!!content);
  }, [content]);

  return (
    <ModalContext.Provider value={value}>
      <M.Modal
        content={content}
        visible={visible}
        setVisible={setModalVisible}
      />
      {children}
    </ModalContext.Provider>
  );
}

export default ModalContext;
export { ModalProvider };
