import React from 'react';
import {
  iBorder,
  iColors,
  iSpacing,
  iStyle,
  iText,
  join,
  withStyle,
} from '../../_HOC/withStyle';
import { Icon } from '../Icon';
import { iIcons } from '../Icon/Icons.type';
import styles from './Link.module.scss';

export interface iLink extends iStyle, iColors, iSpacing, iText, iBorder {
  children: (
    before: React.ReactNode,
    after: React.ReactNode,
    className: string | undefined
  ) => React.ReactNode;
  iconBefore?: iIcons;
  iconAfter?: iIcons;
}

export function LinkComp({
  children,
  iconBefore,
  iconAfter,
  className,
}: iLink): JSX.Element {
  return (
    <>
      {children(
        <Icon icon={iconBefore} mr="10" />,
        <Icon icon={iconAfter} ml="10" />,
        join(styles.Link, className)
      )}
    </>
  );
}

LinkComp.defaultProps = {
  iconBefore: undefined,
  iconAfter: undefined,
};

export const Link = withStyle(LinkComp);
