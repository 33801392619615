import React from 'react';
import { A } from '..';
import styles from './InputRadio.module.scss';

export interface iInputRadio {
  label?: React.ReactNode;
  name: string;
  radios: Array<{ value: string; label: React.ReactNode }>;
  id?: string;
  checked?: string;
  defaultChecked?: boolean;
  disabled?: boolean;
  required?: boolean;
  error?: React.ReactNode;
  onChange?: (e: React.FocusEvent<HTMLInputElement>) => void;
}

export const InputRadio = React.forwardRef(function field(
  {
    label,
    name,
    radios,
    id,
    checked,
    disabled,
    required,
    onChange,
    error,
  }: iInputRadio,
  ref: React.ForwardedRef<HTMLInputElement>
) {
  return (
    <div className={styles.InputRadio}>
      {radios.map((radio) => (
        <label
          className={styles.InputRadio__group}
          htmlFor={`radio-${radio.value}`}
          key={radio.value}
        >
          <input
            type="radio"
            value={radio.value}
            checked={radio.value === checked}
            name={name}
            id={`radio-${radio.value}`}
            className={styles.InputRadio__field}
            disabled={disabled}
            onChange={onChange}
            required={required}
            ref={ref}
          />
          <span
            className={styles.Checkbox__status}
            data-disabled={disabled}
            data-error={!!error}
          />
          <span
            className={styles.InputRadio__label}
            data-disabled={disabled}
            data-error={!!error}
          >
            {radio.label}
            {required && <i data-asterix>*</i>}
          </span>
        </label>
      ))}

      <A.Text as="div" mt="5" fontSize="14" color="alert">
        {error}
      </A.Text>
    </div>
  );
});

InputRadio.defaultProps = {
  checked: undefined,
  id: undefined,
  defaultChecked: undefined,
  disabled: undefined,
  required: undefined,
  onChange: undefined,
  error: undefined,
};
