import React, { useEffect, useRef } from 'react';
import Animation from 'TOMA/_helpers/Animation';
import style from './Extend.module.scss';

interface IExtend {
  children: React.ReactNode;
  extend: boolean;
  duration?: number;
  className?: string;
}

function Extend({
  children,
  extend,
  duration = 300,
  className = '',
}: IExtend): JSX.Element {
  const contentParentRef = useRef<HTMLHeadingElement>(null);
  const contentChildrenRef = useRef<HTMLHeadingElement>(null);

  useEffect(() => {
    const contentParent = contentParentRef.current;
    const contentChildren = contentChildrenRef.current;
    const animation = new Animation();

    if (contentParent && contentChildren) {
      const heightParent = contentParent?.clientHeight || 0;
      const heightChildren = contentChildren?.clientHeight || 0;

      let fromTo: [number, number] = [0, 1];
      if (extend) fromTo = [heightParent, heightChildren];
      else fromTo = [heightParent, 0];

      if (fromTo[0] === 0 && fromTo[1] === 0) {
        contentParent.style.height = '0px';
        contentParent.setAttribute('aria-expanded', 'false');
        contentParent.setAttribute('aria-hidden', 'true');
        contentParent.style.visibility = 'hidden';
      } else {
        animation.start(duration, fromTo, (data, { isStart, isEnd }) => {
          if (isStart) {
            if (fromTo[0] < fromTo[1]) {
              contentParent.setAttribute('aria-expanded', 'true');
              contentParent.setAttribute('aria-hidden', 'false');
              contentParent.style.visibility = 'visible';
            }
          }
          if (isEnd) {
            if (fromTo[1] < fromTo[0]) {
              contentParent.setAttribute('aria-expanded', 'false');
              contentParent.setAttribute('aria-hidden', 'true');
              contentParent.style.visibility = 'hidden';
            }
          }
          if (data === heightChildren) {
            contentParent.style.height = 'auto';
          } else {
            contentParent.style.height = `${data}px`;
          }
        });
      }
    }

    return () => {
      animation.stop();
    };
  }, [extend, duration]);

  return (
    <div className={[className, style.Extend].join(' ')} ref={contentParentRef}>
      <div className={style.Extend__children} ref={contentChildrenRef}>
        {children}
      </div>
    </div>
  );
}

Extend.defaultProps = {
  duration: undefined,
  className: '',
};

export { Extend };
