/* eslint-disable react/no-danger */
import React from 'react';
import {
  iBorder,
  iColors,
  iIcon,
  iSpacing,
  iSizing,
  iStyle,
  iText,
  join,
  withStyle,
} from '../../_HOC/withStyle';
import styles from './Text.module.scss';
import { ReactComponent as Svg } from './loading.svg';

export interface iAlert
  extends iStyle,
    iColors,
    iSpacing,
    iText,
    iSizing,
    iBorder,
    iIcon {
  children: React.ReactNode;
  loading?: boolean;
  as?: 'div' | 'p' | 'span';
}

export const Text = withStyle(
  ({ children, as: TagName = 'div', className, loading, fontSize }: iAlert) => {
    if (loading) {
      return (
        <div
          style={{ height: `calc(${fontSize ?? 16}px + 5px)` }}
          className={join(styles.Text__loading, className)}
        >
          <Svg />
        </div>
      );
    }
    if (!children) return null;
    if (typeof children === 'string') {
      return (
        <TagName
          className={join(styles.Text, className)}
          dangerouslySetInnerHTML={{ __html: children }}
        />
      );
    }
    return (
      <TagName className={join(styles.Text, className)}>{children}</TagName>
    );
  }
);
