import React, { useMemo } from 'react';
import { DeepPartial, useForm, UseFormReturn, Resolver } from 'react-hook-form';

interface iForm<T extends {}> {
  children: (formApi: UseFormReturn<T>, submit: any) => React.ReactNode;
  onSubmit?: (values: T, formApi: UseFormReturn<T>) => void;
  onValid?: (values: any) => void;
  defaultValues?: DeepPartial<T>;
  resolver?: Resolver<T, object>;
}

interface iFormContext {
  required?: string;
  showPassword?: string;
  hidePassword?: string;
  day?: string;
  month?: string;
  year?: string;
  dateFormat?: string;
}

const defaultFormContext: iFormContext = {
  required: 'Champ requis',
  showPassword: 'Afficher le mot de passe',
  hidePassword: 'Cacher le mot de passe',
  day: 'JJ',
  month: 'MM',
  year: 'AAAA',
  dateFormat: 'fr',
};

export const FormContext = React.createContext<iFormContext>({});

export function FormProvider({
  children,
  value,
}: {
  children: React.ReactNode;
  value: iFormContext;
}): JSX.Element {
  const newVal = useMemo(() => ({ ...defaultFormContext, ...value }), [value]);
  return <FormContext.Provider value={newVal}>{children}</FormContext.Provider>;
}

export default function Form<T extends {}>({
  children,
  defaultValues,
  onSubmit,
  onValid,
  resolver,
}: iForm<T>): JSX.Element {
  const formApi = useForm<T>({
    mode: 'onChange',
    reValidateMode: 'onBlur',
    defaultValues,
    resolver,
  });

  const { handleSubmit, getValues } = formApi;

  function submit(): void {
    if (onSubmit) {
      const values = getValues();
      onSubmit(values, formApi);
    }
  }

  async function change(): Promise<void> {
    if (onValid) {
      const values = formApi.getValues();

      const isValid = await formApi.trigger();

      if (isValid) {
        onValid(values);
      }
    }
  }

  return (
    <form onSubmit={handleSubmit(submit)} onChange={change} noValidate>
      {children(formApi, handleSubmit(submit))}
    </form>
  );
}

Form.defaultProps = {
  onSubmit: undefined,
  onValid: undefined,
  defaultValues: undefined,
  resolver: undefined,
};
