import React, { ReactNode } from 'react';
import {
  iBorder,
  iArrow,
  iColors,
  iSizing,
  iSpacing,
  iStyle,
  iTransform,
  withStyle,
} from '../../_HOC/withStyle';

interface iBlock
  extends iStyle,
    iColors,
    iArrow,
    iSpacing,
    iSizing,
    iBorder,
    iTransform {
  children: ReactNode;
  style?: React.CSSProperties | undefined;
}

function BlockComp({ children, style, className }: iBlock): JSX.Element {
  return (
    <div className={className} style={style}>
      {children}
    </div>
  );
}

export const Block = withStyle(BlockComp);
