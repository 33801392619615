import React, { ReactNode } from 'react';
import {
  iBorder,
  iColors,
  iSizing,
  iSpacing,
  iStyle,
  iTransform,
  join,
  withStyle,
} from '../../_HOC/withStyle';
import styles from './Container.module.scss';

interface iContainer
  extends iStyle,
    iColors,
    iSpacing,
    iSizing,
    iBorder,
    iTransform {
  children: ReactNode;
  full?: boolean;
  maxWidth?: `${number}px`;
  // maxWidth?: `${number}px`;
}

export function ContainerComp({
  children,
  full,
  maxWidth,
  className,
}: iContainer): JSX.Element {
  return (
    <div
      className={join(styles.Container, full && styles.full, className)}
      style={{ maxWidth }}
    >
      {children}
    </div>
  );
}

ContainerComp.defaultProps = {
  full: undefined,
  maxWidth: undefined,
};

export const Container = withStyle(ContainerComp);
