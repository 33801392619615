/* eslint-disable @typescript-eslint/prefer-nullish-coalescing */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/method-signature-style */
import * as Yup from 'yup';

declare module 'yup' {
  interface StringSchema {
    minLowercase(length?: number, message?: string): StringSchema;
    minUppercase(length?: number, message?: string): StringSchema;
    minNumbers(length?: number, message?: string): StringSchema;
    minSymbols(length?: number, message?: string): StringSchema;
    pipe(shema?: any, message?: string, translateAnd?: string): StringSchema;
  }
}
const p = (word: string, num: number) => (num === 1 ? word : `${word}s`);

const isNullOrUndefined = (value?: string) =>
  value === null || value === undefined;

function minLowercase(this: any, length?: number, message?: string) {
  const msg =
    message ||
    `\${path} must contain at least \${length} lowercase ${p(
      'letter',
      length || 1
    )}`;
  return this.test({
    name: 'minLowercase',
    exclusive: true,
    message: msg,
    params: { length },
    test(value: string) {
      return (
        isNullOrUndefined(value) ||
        (value.match(/[a-z]/g) || []).length >= (length || 1)
      );
    },
  });
}

function minUppercase(this: any, length?: number, message?: string) {
  const msg =
    message ||
    `\${path} must contain at least \${length} uppercase ${p(
      'letter',
      length || 1
    )}`;
  return this.test({
    name: 'minUppercase',
    exclusive: true,
    message: msg,
    params: { length },
    test(value: string) {
      return (
        isNullOrUndefined(value) ||
        (value.match(/[A-Z]/g) || []).length >= (length || 1)
      );
    },
  });
}

function minNumbers(this: any, length?: number, message?: string) {
  const msg =
    message ||
    `\${path} must contain at least \${length} ${p('number', length || 1)}`;
  return this.test({
    name: 'minNumber',
    exclusive: true,
    message: msg,
    params: { length },
    test(value: string) {
      return (
        isNullOrUndefined(value) ||
        (value.match(/[0-9]/g) || []).length >= (length || 1)
      );
    },
  });
}

function minSymbols(this: any, length?: number, message?: string) {
  const msg =
    message ||
    `\${path} must contain at least \${length} ${p('symbol', length || 1)}`;
  return this.test({
    name: 'minSymbol',
    exclusive: true,
    message: msg,
    params: { length },
    test(value: string) {
      return (
        isNullOrUndefined(value) ||
        (value.match(/[^a-zA-Z0-9\s]/g) || []).length >= (length || 1)
      );
    },
  });
}

function pipe(
  this: any,
  shema: any,
  message: string,
  translateAnd: string = 'and'
) {
  const msg = message || `\${items}`;
  const params = { items: '' };
  return this.test({
    name: 'pipe',
    exclusive: true,
    message: msg,
    params,
    async test(value: any) {
      let items: string[] = [];
      try {
        await shema.validate(value, { abortEarly: false });
      } catch (err: any) {
        items = err.errors;
      }
      params.items = items.reduce<string>((acc, val, index, values) => {
        if (index === values.length - 1) {
          return acc + val;
        }
        if (index === values.length - 2) {
          return `${acc + val} ${translateAnd} `;
        }
        return `${acc + val}, `;
      }, '');
      return !items.length;
    },
  });
}

export function yupCustomMethods(yup: typeof Yup): void {
  yup.addMethod(yup.string, 'minLowercase', minLowercase);
  yup.addMethod(yup.string, 'minUppercase', minUppercase);
  yup.addMethod(yup.string, 'minNumbers', minNumbers);
  yup.addMethod(yup.string, 'minSymbols', minSymbols);
  yup.addMethod(yup.string, 'pipe', pipe);
}
