import React, { useEffect, useState, useRef, useImperativeHandle } from 'react';
import { T, O, M, A } from 'TOMA';
import styles from './InputSelect2.module.scss';

interface iValue {
  value: string | number;
  label: string;
  disabled?: boolean;
}

interface iAutocomplete {
  keyName: string | number | undefined;
  name: string;
  label?: React.ReactNode;
  placeholder: string;
  options: iValue[] | undefined;
  optionsLoading?: boolean;
  onChange?: (value: {
    value: string | number | undefined;
    label: string;
  }) => void;
  onBlur?: () => void;
  onFocus?: () => void;
  error?: React.ReactNode;
  disabled?: boolean;
  required?: boolean;
}

export const InputSelect2 = React.forwardRef(function field(
  {
    name,
    label,
    placeholder,
    options,
    optionsLoading,
    keyName,
    onChange = () => {},
    onBlur = () => {},
    onFocus = () => {},
    error,
    disabled,
    required,
  }: iAutocomplete,
  ref: React.ForwardedRef<HTMLButtonElement>
): JSX.Element {
  const inputRef = useRef<HTMLButtonElement>(null);
  const clickRef = useRef<boolean>(false);
  const wrapRef = useRef<HTMLDivElement>(null);
  const listRef = useRef<HTMLUListElement>(null);
  const [showChevron, setShowChevron] = useState<boolean>(true);
  const [showList, setShowList] = useState<boolean>(false);
  const [value, setValue] = useState<string>('');

  useImperativeHandle(ref, () => inputRef.current as HTMLButtonElement);

  function handleClick(): void {
    if (!isListDown()) setShowChevron(true);
    setShowList(true);
  }

  function handleBlur(e: React.ChangeEvent<HTMLButtonElement>): void {
    if (!clickRef.current) {
      setShowList(false);
      onBlur();
    }
  }

  useEffect(() => {
    const val = options?.reduce<string | undefined>((acc, option) => {
      return option.value === keyName ? option.label : acc;
    }, undefined);
    setValue(val ?? '');
  }, [keyName, optionsLoading, options]);

  function isListDown(): boolean {
    return !!(
      wrapRef.current &&
      listRef.current &&
      wrapRef.current?.scrollTop + wrapRef.current?.offsetHeight >=
        listRef.current?.offsetHeight
    );
  }

  function onScroll(e: any): void {
    if (!e.target) return;
    if (isListDown()) {
      setShowChevron(false);
    }
  }

  useEffect(() => {
    wrapRef.current?.addEventListener('scroll', onScroll);

    return () => {
      wrapRef.current?.removeEventListener('scroll', onScroll);
    };
  }, []);

  return (
    <div className={styles.InputSelect2}>
      <div className={styles.InputSelect2__group}>
        <div
          className={styles.InputSelect2__content}
          role="combobox"
          aria-controls={`${name}-listbox`}
          aria-expanded={!!showList}
          aria-owns={`${name}-listbox`}
          aria-haspopup="listbox"
          id={`${name}-combobox`}
        >
          {label && (
            <label
              id={`${name}-label`}
              className={styles.InputSelect2__label}
              data-disabled={disabled}
            >
              {label}
              {required && <i data-asterix>*</i>}
            </label>
          )}
          <div
            className={styles.InputSelect2__wrapField}
            data-disabled={disabled}
          >
            <button
              type="button"
              className={styles.InputSelect2__field}
              id={`${name}-input`}
              aria-autocomplete="list"
              aria-controls={`${name}-listbox`}
              aria-describedby={`desc-${name}`}
              aria-invalid={!!error}
              aria-required={required}
              data-error={!!error}
              data-placeholder={!value}
              ref={inputRef}
              onMouseDown={handleClick}
              onBlur={handleBlur}
              onFocus={onFocus}
              disabled={disabled}
              data-option-disabled={
                options?.find((e) => e.value === keyName)?.disabled
              }
            >
              {optionsLoading ? 'Chargement...' : value || placeholder}
            </button>
            {optionsLoading && !showList && (
              <div className={styles.InputSelect2__field__loading}>
                <A.Loading width={20} color="grey" />
              </div>
            )}
          </div>
          <div
            aria-labelledby={`${name}-label`}
            role="listbox"
            id={`${name}-listbox`}
            className={styles.InputSelect2__list}
            aria-hidden={!showList}
          >
            {(!options || optionsLoading) && (
              <div className={styles.InputSelect2__list__loading}>
                <A.Loading color="secondary" />
              </div>
            )}
            <div className={styles.InputSelect2__list__wrap} ref={wrapRef}>
              <ul className={styles.InputSelect2__list__ul} ref={listRef}>
                {!!options?.length &&
                  options?.map((opt) => (
                    <li
                      className={styles.InputSelect2__list__li}
                      role="option"
                      tabIndex={-1}
                      aria-selected={opt.value === keyName}
                      onMouseDown={() => (clickRef.current = true)}
                      onMouseUp={() => {
                        if (opt.disabled) return;
                        setTimeout(() => {
                          setShowList(false);
                        }, 300);
                        onChange({ ...opt });
                        if (clickRef.current) {
                          clickRef.current = false;
                          onBlur();
                        }
                      }}
                      onKeyDown={() => {}}
                      key={opt.value}
                      data-disabled={opt.disabled}
                    >
                      {opt.value === keyName && (
                        <A.Icon
                          icon="check"
                          className={styles.InputSelect2__list__icon}
                        />
                      )}
                      <A.InnerHTML html={opt.label} />
                    </li>
                  ))}
              </ul>
            </div>

            {!!options && options.length > 5 && showChevron && (
              <div className={styles.InputSelect2__list__chevron}>
                <A.Icon icon="chevron-down" />
              </div>
            )}
          </div>
        </div>
      </div>

      <A.Text as="div" mt="5" fontSize="14" color="alert">
        {error}
      </A.Text>
    </div>
  );
});

InputSelect2.defaultProps = {
  label: undefined,
};
