import React from 'react';
import {
  iBorder,
  iColors,
  iSpacing,
  iStyle,
  iText,
  join,
  withStyle,
} from '../../_HOC/withStyle';
import styles from './Title.module.scss';

export interface iTitle extends iStyle, iColors, iSpacing, iText, iBorder {
  children: React.ReactNode;
  as: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6' | 'div';
}

function TitleComp({ as: TagName, children, className }: iTitle): JSX.Element {
  return (
    <TagName className={join(styles.Title, className)}>{children}</TagName>
  );
}

export const Title = withStyle(TitleComp);
