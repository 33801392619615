import Client from '../Client.provider';
import { iMediaRest } from './Media.type';

export interface iMediaRepository {
  get: (mediaId: string) => Promise<any>;
  upload: (params: any) => Promise<any>;
  remove: (type: string, mediaId: string) => Promise<void>;
  uploadFile: (params: any) => Promise<any>;
}

export class MediaRepository extends Client implements iMediaRepository {
  public async get(mediaId: string): Promise<iMediaRest> {
    return await this.restricted()
      .get<iMediaRest>(`/media/${mediaId}`)
      .then((e) => e.data)
      .catch((error) => {
        throw new Error(
          error.response.data.message ||
            JSON.stringify(error.response.data)
              .replace(/[{}[\]"]/g, '')
              .replace(/,/g, '<br/>') ||
            `${error.response.status} | Unknow error...`
        );
      });
  }

  public async upload(form: FormData): Promise<iMediaRest> {
    return await this.restricted()
      .post<iMediaRest>(`/media`, form)
      .then((e) => e.data)
      .catch((error) => {
        throw new Error(
          error.response.data.message ||
            JSON.stringify(error.response.data)
              .replace(/[{}[\]"]/g, '')
              .replace(/,/g, '<br/>') ||
            `${error.response.status} | Unknow error...`
        );
      });
  }

  public async remove(type: string, mediaId: string): Promise<void> {
    return await this.restricted()
      .delete(`/media/${type}/${mediaId}`)
      .then((e) => e.data)
      .catch((error) => {
        throw new Error(
          error.response.data.message ||
            JSON.stringify(error.response.data)
              .replace(/[{}[\]"]/g, '')
              .replace(/,/g, '<br/>') ||
            `${error.response.status} | Unknow error...`
        );
      });
  }

  public async uploadFile(form: FormData): Promise<iMediaRest> {
    return await this.restricted()
      .post<iMediaRest>(`/file`, form)
      .then((e) => e.data)
      .catch((error) => {
        throw new Error(
          error.response.data.message ||
            JSON.stringify(error.response.data)
              .replace(/[{}[\]"]/g, '')
              .replace(/,/g, '<br/>') ||
            `${error.response.status} | Unknow error...`
        );
      });
  }
}
