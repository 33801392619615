import React from 'react';
import {
  iColors,
  iSpacing,
  iStyle,
  iText,
  join,
  withStyle,
} from 'TOMA/_HOC/withStyle';
import styles from './Line.module.scss';

export interface iLine extends iStyle, iSpacing, iText, iColors {}

export const Line = withStyle(
  ({ className }: iLine): JSX.Element => (
    <div className={join(className, styles.Line)} />
  )
);
