/* eslint-disable react/button-has-type */
import React from 'react';
import {
  iStyle,
  iColors,
  iBorder,
  iText,
  iSpacing,
  iIcon,
  join,
  withStyle,
} from '../../_HOC/withStyle';
import styles from './Button.module.scss';
import { Loading } from '../Loading';

export interface iButton
  extends iStyle,
    iColors,
    iBorder,
    iText,
    iSpacing,
    iIcon {
  children: React.ReactNode;
  type?: 'button' | 'submit' | 'reset';
  appearance?: 'button' | 'link' | 'small';
  center?: boolean;
  full?: boolean;
  loading?: boolean;
  progress?: number;
  disabled?: boolean;
  onClick?: (
    e:
      | React.MouseEvent<HTMLButtonElement | HTMLDivElement, MouseEvent>
      | React.KeyboardEvent<HTMLDivElement>
  ) => void;
}

export const Button = withStyle(
  ({
    children,
    type = 'button',
    appearance = 'button',
    className,
    onClick,
    center,
    loading,
    progress,
    disabled,
    full,
  }: iButton) =>
    appearance === 'button' || appearance === 'small' ? (
      <button
        type={type}
        onClick={onClick}
        data-appearance={appearance}
        data-center={center}
        data-full={full}
        data-loading={loading}
        disabled={disabled}
        className={join(styles.Button, className, 'bgprimary', 'colorwhite')}
      >
        {children}
        {loading && <Loading width="20px" color="white" absolute />}
        {!!progress && (
          <div className={styles.progress}>
            <div
              className={styles.progress__status}
              style={{ transform: `scaleX(${progress || 0})` }}
            />
          </div>
        )}
      </button>
    ) : (
      <div
        onKeyPress={(e) => {
          if (e.key === 'Enter') onClick?.(e);
        }}
        onClick={onClick}
        className={join(
          styles.Div,
          className,
          // 'bgprimary',
          'bgtransparent',
          'colorwhite'
        )}
        role="button"
        aria-pressed="false"
        data-disabled={disabled}
        tabIndex={0}
      >
        {children}
        {loading && <Loading width="20px" color="white" absolute />}
        {!!progress && (
          <div className={styles.progress}>
            <div
              className={styles.progress__status}
              style={{ transform: `scaleX(${progress || 0})` }}
            />
          </div>
        )}
      </div>
    )
);
