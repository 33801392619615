import React, { useContext } from 'react';
import {
  Control,
  FieldValues,
  useController,
  UseControllerProps,
} from 'react-hook-form';
import { A } from 'TOMA';
import { FormContext } from '../Form';

type iInputPhone<T extends FieldValues> = {
  name: string;
  control: Control<T>;
  label?: string;
  placeholder?: string;
  required?: boolean;
  disabled?: boolean;
} & UseControllerProps<T>;

export function InputPhone<T extends FieldValues>({
  name,
  control,
  label,
  placeholder,
  required,
  disabled,
}: iInputPhone<T>): JSX.Element {
  const {
    field: { onChange, onBlur, value, ref },
    fieldState: { isTouched },
    formState: { errors, isSubmitted },
  } = useController({
    name,
    control,
  });

  const path = name.split('.');
  let key;
  let error: any = errors;
  // eslint-disable-next-line no-cond-assign
  while ((key = path.shift())) {
    if (!Number.isNaN(parseInt(key, 10))) {
      key = parseInt(key, 10);
    }
    if (!error[key]) {
      error = undefined;
      break;
    }
    error = error[key];
  }

  const errorMessage = (isTouched || isSubmitted) && error && error.message;

  return (
    <A.InputPhone
      name={name}
      label={label}
      placeholder={placeholder}
      value={value}
      onChange={onChange}
      onBlur={onBlur}
      disabled={disabled}
      required={required}
      error={errorMessage}
    />
  );
}
