import React from 'react';
import { ReactComponent as Loader } from './loader.svg';
import style from './Loading.module.scss';

interface ILoading {
  width?: React.CSSProperties['width'];
  color?: 'primary' | 'secondary' | 'tertiary' | 'white' | 'grey';
  absolute?: boolean;
}

export function Loading({
  width,
  color = 'primary',
  absolute,
}: ILoading): JSX.Element {
  return (
    <span className={style.Loading} data-color={color} data-absolute={absolute}>
      <Loader className={style.Loading__svg} style={{ width, height: width }} />
    </span>
  );
}

Loading.defaultProps = {
  width: null,
  color: 'primary',
  absolute: undefined,
};
