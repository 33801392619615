import React, { useContext } from 'react';
import {
  Control,
  FieldValues,
  useController,
  UseControllerProps,
} from 'react-hook-form';
import { A } from 'TOMA';
import { FormContext } from '../Form';

type iInputTextarea<T extends FieldValues> = {
  name: string;
  control: Control<T>;
  label?: string;
  placeholder?: string;
  required?: boolean;
  disabled?: boolean;
  rows?: number;
  counter?: number;
} & UseControllerProps<T>;

export function InputTextarea<T extends FieldValues>({
  name,
  control,
  label,
  placeholder,
  required,
  disabled,
  rows,
  counter,
}: iInputTextarea<T>): JSX.Element {
  const translation = useContext(FormContext);
  const {
    field: { onChange, onBlur, value, ref },
    fieldState: { isTouched },
    formState: { errors, isSubmitted },
  } = useController({
    name,
    control,
    rules: {
      required: required === true ? translation.required : required,
    },
  });

  const path = name.split('.');
  let key;
  let error: any = errors;

  while ((key = path.shift())) {
    if (!Number.isNaN(parseInt(key, 10))) {
      key = parseInt(key, 10);
    }
    if (!error[key]) {
      error = undefined;
      break;
    }
    error = error[key];
  }

  const errorMessage = (isTouched || isSubmitted) && error && error.message;

  return (
    <A.InputTextarea
      name={name}
      label={label}
      placeholder={placeholder}
      value={value || ''}
      onChange={onChange}
      onBlur={onBlur}
      ref={ref}
      required={required}
      error={errorMessage}
      disabled={disabled}
      rows={rows}
      counter={counter}
    />
  );
}
InputTextarea.defaultProps = {
  label: undefined,
  disabled: undefined,
  required: undefined,
  placeholder: undefined,
};
