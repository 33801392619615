import React, { useEffect, useRef, useState } from 'react';
import { iSpacing, iStyle, iText, join, withStyle } from '../../_HOC/withStyle';
import { A } from '..';
import style from './Popover.module.scss';

interface iPopover extends iStyle, iText, iSpacing {
  children: React.ReactNode;
  position?: 'left' | 'right' | 'top' | 'bottom' | 'auto';
}

const Popover = withStyle(
  ({ children, position = 'bottom', className }: iPopover) => {
    const [hidden, setHidden] = useState(true);
    const popoverRef = useRef<HTMLDivElement>(null);
    const buttonRef = useRef<HTMLButtonElement>(null);
    const contentRef = useRef<HTMLDivElement>(null);

    function show(): void {
      if (buttonRef.current && popoverRef.current && contentRef.current) {
        popoverRef.current.setAttribute('data-position', position);

        const button = buttonRef.current?.getBoundingClientRect();
        const content = contentRef.current?.getBoundingClientRect();
        const bottom = window.innerHeight - button.y - button.height;
        const top = button.y;
        const right = window.innerWidth - button.x - button.width;
        const left = button.x;

        if (position === 'bottom' && bottom < content.height) {
          popoverRef.current.setAttribute('data-position', 'top');
        }

        if (position === 'top' && top < content.height) {
          popoverRef.current.setAttribute('data-position', 'bottom');
        }

        if (position === 'left' && left < content.width) {
          popoverRef.current.setAttribute('data-position', 'right');
        }

        if (position === 'right' && right < content.width) {
          popoverRef.current.setAttribute('data-position', 'left');
        }
      }
      setHidden(false);
    }

    function hide(): void {
      setHidden(true);
    }

    useEffect(() => {
      buttonRef.current?.addEventListener('click', show, false);
      popoverRef.current?.addEventListener('mouseenter', show, false);
      popoverRef.current?.addEventListener('mouseleave', hide, false);

      return () => {
        buttonRef.current?.removeEventListener('click', show, false);
        popoverRef.current?.removeEventListener('mouseenter', show, false);
        popoverRef.current?.removeEventListener('mouseleave', hide, false);
      };
    }, []);

    return (
      <div className={style.Popover} ref={popoverRef}>
        <button type="button" className={style.Popover__button} ref={buttonRef}>
          <A.Icon
            icon="question-circle"
            className={join(className, style.Popover__icon)}
          />
        </button>
        <div className={style.Popover__container} aria-hidden={hidden}>
          <div className={style.Popover__content} ref={contentRef}>
            {children}
          </div>
        </div>
      </div>
    );
  }
);

export { Popover };
