import React, { useState } from 'react';
import { A } from '..';
import Slider from 'rc-slider';
import Tooltip from 'rc-tooltip';
import 'rc-slider/assets/index.css';
import 'rc-tooltip/assets/bootstrap.css';
import styles from './InputSlider.module.scss';

const HandleTooltip = (props: {
  value: number;
  children: React.ReactElement;
  dragging: boolean;
  index: number;
  disabled: boolean;
}): JSX.Element => {
  const { value, children, dragging, index, disabled } = props;

  const tooltipRef = React.useRef<any>();

  React.useEffect(() => {
    tooltipRef.current?.forcePopupAlign();
  }, [value]);

  React.useEffect(() => {
    const si = setInterval(() => {
      tooltipRef.current?.forcePopupAlign();
    }, 1000);
    return () => {
      clearInterval(si);
    };
  }, []);

  const formatedValue = value.toLocaleString();

  return (
    <Tooltip
      placement="top"
      // placement={index ? 'right' : 'left'}
      overlay={formatedValue}
      ref={tooltipRef}
      // visible={dragging}
      visible
      overlayInnerStyle={{
        color: disabled ? 'var(--grey-30)' : 'var(--grey-70)',
      }}
    >
      {children}
    </Tooltip>
  );
};

export interface iInputSlider {
  name: string;
  id?: string;
  min?: number;
  max?: number;
  step?: number;
  pushable?: boolean | number;
  value?: number | number[];
  defaultValue?: number | number[];
  disabled?: boolean;
  required?: boolean;
  placeholder?: string;
  label?: string;
  error?: React.ReactNode;
  onChange?: (e: number | number[]) => void;
}

export const InputSlider = React.forwardRef(function field(
  {
    name,
    id,
    min,
    max,
    step,
    pushable,
    value,
    defaultValue,
    disabled,
    required,
    onChange,
    label,
    error,
  }: iInputSlider,
  ref: React.ForwardedRef<HTMLDivElement>
) {
  return (
    <div className={styles.InputSlider}>
      {label && (
        <label
          htmlFor={id ?? name}
          className={styles.InputSlider__label}
          data-error={!!error}
          data-disabled={disabled}
        >
          {label}
          {required && <i data-asterix>*</i>}
        </label>
      )}
      <div className={styles.InputSlider__group} ref={ref}>
        <Slider
          // range
          min={min}
          max={max}
          step={step}
          pushable={pushable}
          value={value}
          defaultValue={defaultValue}
          handleRender={(node, handleProps) => {
            return (
              <HandleTooltip
                value={handleProps.value}
                index={handleProps.index}
                dragging={handleProps.dragging}
                disabled={!!disabled}
              >
                {node}
              </HandleTooltip>
            );
          }}
          onChange={onChange}
          disabled={disabled}
        />
      </div>

      <A.Text as="div" mt="5" fontSize="14" color="alert">
        {error}
      </A.Text>
    </div>
  );
});

InputSlider.defaultProps = {
  id: undefined,
  min: undefined,
  max: undefined,
  step: undefined,
  pushable: undefined,
  defaultValue: undefined,
  disabled: undefined,
  required: undefined,
  placeholder: undefined,
  label: undefined,
  error: undefined,
  onChange: undefined,
};
