import React from 'react';
import { A } from '..';
import styles from './InputCheckbox.module.scss';

export interface iInputCheckbox {
  label: React.ReactNode;
  name: string;
  id?: string;
  checked?: boolean;
  defaultChecked?: boolean;
  disabled?: boolean;
  required?: boolean;
  error?: React.ReactNode;
  onChange?: (e: React.FocusEvent<HTMLInputElement>) => void;
}

export const InputCheckbox = React.forwardRef(function field(
  {
    label,
    name,
    id,
    checked,
    defaultChecked,
    disabled,
    required,
    onChange,
    error,
  }: iInputCheckbox,
  ref: React.ForwardedRef<HTMLInputElement>
) {
  return (
    <div className={styles.InputCheckbox}>
      <label className={styles.InputCheckbox__group} htmlFor={id ?? name}>
        <input
          type="checkbox"
          checked={checked}
          defaultChecked={defaultChecked}
          name={name}
          id={id ?? name}
          className={styles.InputCheckbox__field}
          disabled={disabled}
          onChange={onChange}
          required={required}
          ref={ref}
        />
        <span
          className={styles.Checkbox__status}
          data-disabled={disabled}
          data-error={!!error}
        />
        <span
          className={styles.InputCheckbox__label}
          data-disabled={disabled}
          data-error={!!error}
        >
          {label}
          {required && <i data-asterix>*</i>}
        </span>
      </label>

      <A.Text as="div" mt="5" fontSize="14" color="alert">
        {error}
      </A.Text>
    </div>
  );
});

InputCheckbox.defaultProps = {
  checked: undefined,
  id: undefined,
  defaultChecked: undefined,
  disabled: undefined,
  required: undefined,
  onChange: undefined,
  error: undefined,
};
