import React from 'react';

export function InnerHTML({
  html,
  as: TagName = 'div',
  className,
}: {
  html: string | undefined;
  as?: 'div' | 'span';
  className?: string;
}): JSX.Element | null {
  if (html === undefined) return null;
  return (
    <TagName dangerouslySetInnerHTML={{ __html: html }} className={className} />
  );
}

InnerHTML.defaultProps = {
  as: undefined,
  className: undefined,
};
