import React, { useContext, useEffect } from 'react';
import {
  Control,
  FieldValues,
  useController,
  UseControllerProps,
} from 'react-hook-form';
import { A } from 'TOMA';
import { FormContext } from '../Form';

type iInputSelect<T extends FieldValues> = {
  name: string;
  control: Control<T>;
  label?: string;
  placeholder?: string;
  required?: string | boolean;
  disabled?: boolean;
  options:
    | Array<{ value: string | number; label: string; disabled?: boolean }>
    | undefined;
  onChange?: (e: string) => void;
} & UseControllerProps<T>;
export function InputSelect<T extends FieldValues>({
  name,
  control,
  label,
  placeholder,
  required,
  disabled,
  options,
  onChange: handleChange,
}: iInputSelect<T>): JSX.Element {
  const translation = useContext(FormContext);
  const {
    field: { onChange, onBlur, value, ref },
    fieldState: { isTouched },
    formState: { errors, isSubmitted },
  } = useController({
    name,
    control,
    rules: {
      required: required === true ? translation.required : required,
    },
  });
  const path = name.split('.');
  let key;
  let error: any = errors;
  while ((key = path.shift())) {
    if (!Number.isNaN(parseInt(key, 10))) {
      key = parseInt(key, 10);
    }
    if (!error[key]) {
      error = undefined;
      break;
    }
    error = error[key];
  }
  const errorMessage = (isTouched || isSubmitted) && error && error.message;

  useEffect(() => {
    if (options?.length === 1) {
      onChange(options[0].value);
    }
  }, [options?.toString()]);

  return (
    <A.InputSelect
      name={name}
      label={label}
      placeholder={placeholder}
      value={value || ''}
      onChange={({ target }) => {
        if (handleChange) handleChange(target.value);
        onChange(target.value);
        onBlur();
      }}
      ref={ref}
      required={!!required}
      error={errorMessage}
      disabled={disabled}
      options={options}
    />
  );
}
InputSelect.defaultProps = {
  inputMode: undefined,
  label: undefined,
  disabled: undefined,
  required: undefined,
  placeholder: undefined,
};
