import React, { useEffect, useRef, useState, useImperativeHandle } from 'react';
import { format, isValid } from 'date-fns';
import {
  withStyle,
  iStyle,
  iSizing,
  iSpacing,
  join,
} from '../../_HOC/withStyle';
import { A } from '..';
import style from './InputDate.module.scss';

interface iInputDate extends iStyle, iSizing, iSpacing {
  name: string;
  id?: string;
  label?: React.ReactNode;
  value?: Date;
  required?: boolean;
  onChange?: (e: Date) => void;
  onBlur?: (e: React.FocusEvent<HTMLInputElement>) => void;
  error?: string;
  disabled?: boolean;
  big?: boolean;
  shakeOnError?: boolean;
  labelDay?: string;
  labelMonth?: string;
  labelYear?: string;
  dateFormat?: string;
  className?: string;
}

const InputDateComp = React.forwardRef(function field(
  {
    name,
    id,
    label,
    value,
    required,
    onChange,
    onBlur,
    error,
    disabled,
    big,
    shakeOnError,
    className,
    labelDay = 'JJ',
    labelMonth = 'MM',
    labelYear = 'AAAA',
    dateFormat = 'fr',
  }: iInputDate,
  ref: React.ForwardedRef<HTMLInputElement>
) {
  const inputDateRef = useRef<HTMLDivElement>(null);
  const dayRef = useRef<HTMLInputElement>(null);
  const [focus, setFocus] = useState(false);
  const [dayState, setDayState] = useState('');
  const [monthState, setMonthState] = useState('');
  const [yearState, setYearState] = useState('');
  const day = useRef('');
  const month = useRef('');
  const year = useRef('');
  const monthRef = useRef<HTMLInputElement>(null);
  const yearRef = useRef<HTMLInputElement>(null);
  const isDirtyInput = useRef(false);

  useImperativeHandle(ref, () => dayRef.current as HTMLInputElement);

  let sto: any = null;

  useEffect(() => {
    if (shakeOnError && error && inputDateRef.current) {
      inputDateRef.current.setAttribute('data-shake', 'true');
      setTimeout(() => {
        inputDateRef.current?.removeAttribute('data-shake');
      }, 900);
    }
  }, [shakeOnError, error]);

  useEffect(() => {
    function select(e: FocusEvent): void {
      (e.target as HTMLInputElement).select();
    }

    dayRef.current?.addEventListener('focus', select);
    monthRef.current?.addEventListener('focus', select);
    yearRef.current?.addEventListener('focus', select);

    return () => {
      dayRef.current?.removeEventListener('focus', select);
      monthRef.current?.removeEventListener('focus', select);
      yearRef.current?.removeEventListener('focus', select);
    };
  }, []);

  function convertDate(y: string, m: string, d: string): Date {
    const yint = parseInt(y, 10);
    const mint = parseInt(m, 10);
    const dint = parseInt(d, 10);
    const dt = new Date(yint, mint - 1, dint);

    return dt;
  }

  function handleChange(blur?: boolean): void {
    if (!onChange) return;

    const date = convertDate(year.current, month.current, day.current);

    if (!isValid(date) && !isDirtyInput.current && !blur) return;

    if (
      year.current.length === 4 &&
      month.current.length === 2 &&
      day.current.length === 2
    ) {
      onChange(date);
    }

    if (!isDirtyInput.current) isDirtyInput.current = true;

    if (blur) {
      onChange(date);
    }
  }

  function handleFocus(): void {
    setFocus(true);
    clearTimeout(sto);
  }

  function handleBlur(e: React.FocusEvent<HTMLInputElement, Element>): void {
    handleChange();
    sto = setTimeout(async () => {
      setFocus(false);
      handleChange(true);
      if (onBlur) onBlur(e);
    });
  }

  useEffect(() => {
    if (value && isValid(value)) {
      day.current = format(value, 'dd');
      month.current = format(value, 'MM');
      year.current = format(value, 'yyyy');
      setDayState(day.current);
      setMonthState(month.current);
      setYearState(year.current);
    }
  }, [value]);

  return (
    <div className={join(className, style.InputDate)} ref={inputDateRef}>
      {label && (
        <label
          htmlFor={`inputDate-${id ?? name}`}
          className={style.InputDate__label}
        >
          {label}
          {required && <i data-asterix>*</i>}
        </label>
      )}

      <span
        className={style.InputDate__fieldGroup}
        data-focus={focus}
        data-error={!!error}
        data-disabled={disabled}
        data-big={big}
      >
        {dateFormat === 'fr' && (
          <>
            <input
              name={`${name}-day`}
              type="text"
              inputMode="numeric"
              placeholder={labelDay}
              maxLength={2}
              value={dayState}
              onChange={(e) => {
                const val = (e.target as HTMLInputElement).value.replace(
                  /\D/g,
                  ''
                );
                day.current = val;
                handleChange();
                setDayState(val);
                if (val.length === 2) monthRef?.current?.focus();
              }}
              onFocus={handleFocus}
              onBlur={handleBlur}
              className={style.InputDate__day}
              required={required}
              id={`inputDate-${id ?? name}`}
              data-error={!!error}
              disabled={disabled}
              ref={dayRef}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  const val = (e.target as HTMLInputElement).value.replace(
                    /\D/g,
                    ''
                  );
                  day.current = val;
                  handleChange();
                  setDayState(val);
                  (e.target as HTMLInputElement).blur();
                  e.preventDefault();
                }
              }}
            />

            <span>/</span>
          </>
        )}

        <input
          name={`${name}-month`}
          type="text"
          inputMode="numeric"
          placeholder={labelMonth}
          maxLength={2}
          value={monthState}
          onChange={(e) => {
            const val = (e.target as HTMLInputElement).value.replace(/\D/g, '');
            month.current = val;
            handleChange();
            setMonthState(val);
            if (dateFormat === 'en') {
              if (val.length === 2) dayRef?.current?.focus();
            } else if (val.length === 2) yearRef?.current?.focus();
          }}
          ref={monthRef}
          onFocus={handleFocus}
          onBlur={handleBlur}
          required={required}
          className={style.InputDate__month}
          data-error={!!error}
          disabled={disabled}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              const val = (e.target as HTMLInputElement).value.replace(
                /\D/g,
                ''
              );
              month.current = val;
              handleChange();
              setMonthState(val);
              (e.target as HTMLInputElement).blur();
              e.preventDefault();
            }
          }}
        />

        <span>/</span>

        {dateFormat === 'en' && (
          <>
            <input
              name={`${name}-day`}
              type="text"
              inputMode="numeric"
              placeholder={labelDay}
              maxLength={2}
              value={dayState}
              onChange={(e) => {
                const val = (e.target as HTMLInputElement).value.replace(
                  /\D/g,
                  ''
                );
                day.current = val;
                handleChange();
                setDayState(val);
                if (val.length === 2) yearRef?.current?.focus();
              }}
              onFocus={handleFocus}
              onBlur={handleBlur}
              className={style.InputDate__day}
              required={required}
              id={`inputDate-${id ?? name}`}
              data-error={!!error}
              disabled={disabled}
              ref={dayRef}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  const val = (e.target as HTMLInputElement).value.replace(
                    /\D/g,
                    ''
                  );
                  day.current = val;
                  handleChange();
                  setDayState(val);
                  (e.target as HTMLInputElement).blur();
                  e.preventDefault();
                }
              }}
            />

            <span>/</span>
          </>
        )}

        <input
          name={`${name}-yeah`}
          type="text"
          inputMode="numeric"
          placeholder={labelYear || 'AAAA'}
          maxLength={4}
          value={yearState}
          onChange={(e) => {
            const val = (e.target as HTMLInputElement).value.replace(/\D/g, '');
            year.current = val;
            handleChange();
            setYearState(val);
          }}
          ref={yearRef}
          onFocus={handleFocus}
          onBlur={handleBlur}
          required={required}
          className={style.InputDate__year}
          data-error={!!error}
          disabled={disabled}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              const val = (e.target as HTMLInputElement).value.replace(
                /\D/g,
                ''
              );
              year.current = val;
              handleChange();
              setYearState(val);
              (e.target as HTMLInputElement).blur();
              e.preventDefault();
            }
          }}
        />
      </span>

      <A.Text as="div" mt="5" fontSize="14" color="alert">
        {error}
      </A.Text>
    </div>
  );
});

InputDateComp.defaultProps = {
  label: undefined,
  id: undefined,
  value: undefined,
  required: undefined,
  error: undefined,
  onChange: undefined,
  onBlur: undefined,
  disabled: undefined,
  big: undefined,
  shakeOnError: undefined,
  labelDay: undefined,
  labelMonth: undefined,
  labelYear: undefined,
  dateFormat: undefined,
};

export const InputDate = withStyle(InputDateComp);
