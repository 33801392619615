import Client from '../Client.provider';

export enum eRoles {
  ADMIN = 'ADMIN',
  EXHIBITOR = 'EXHIBITOR',
}

export interface iUser {
  email: string;
  role: eRoles;
  firstname: string;
  lastname: string;
  phone: string;
  phone_port?: string;
}

export interface iUserRest {
  id: number;
  email: string;
  role: eRoles;
  firstname: string;
  lastname: string;
  phone: string;
  phone_port: string;
  token: string;
  token_type: string;
  expires_in: number;
  expires_at: number;
}

export interface iUserRepository {
  login: (email: string, password: string) => Promise<iUser>;
  register: (
    firstname: string,
    lastname: string,
    phone: string,
    raison_sociale: string,
    siret: string,
    email: string,
    password: string
  ) => Promise<iUser>;
  reconnectUser: () => Promise<void>;
  getUser: () => iUser | null;
  forgotPassword: (email: string) => Promise<string>;
  updatePassword: (
    password: string,
    token: string | undefined
  ) => Promise<string>;
  logout: () => Promise<string>;
  updateUser: (
    param: {
      email: string;
      firstname: string;
      lastname: string;
      phone: string;
      phone_port: string;
    },
    userId: number
  ) => Promise<iUser | null>;
  updateUserActive: (status: boolean, userId: number) => Promise<boolean>;
  remove: (userId: number) => Promise<boolean>;
}

export class UserRepository extends Client implements iUserRepository {
  private _user: iUser | null = null;

  public async login(email: string, password: string): Promise<iUser> {
    return await this.public()
      .post<iUserRest>(`/user/login`, {
        email,
        password,
      })
      .then((e) => e.data)
      .then((userRest) => this.setUser(userRest))
      .catch((error) => {
        throw new Error(
          error.response.data.message ||
            `${error.response.status} | Unknow error...`
        );
      });
  }

  public async register(
    firstname: string,
    lastname: string,
    phone: string,
    raison_sociale: string,
    siret: string,
    email: string,
    password: string
  ): Promise<iUser> {
    return await this.public()
      .post<iUserRest>(`/user/register`, {
        firstname,
        lastname,
        phone,
        raison_sociale,
        siret,
        email,
        password,
      })
      .then((e) => e.data)
      .then((userRest) => this.setUser(userRest))
      .catch((error) => {
        throw new Error(
          error.response.data.message ||
            JSON.stringify(error.response.data)
              .replace(/[{}[\]"]/g, '')
              .replace(/,/g, '<br/>') ||
            `${error.response.status} | Unknow error...`
        );
      });
  }

  public async forgotPassword(email: string): Promise<string> {
    return await this.restricted()
      .post<{ message: string }>(`/user/forgot-password`, { email })
      .then((e) => {
        return e.data.message;
      });
  }

  public async updatePassword(
    password: string,
    token: string | undefined
  ): Promise<string> {
    return await this.public()
      .post<{ message: string }>(`/user/update-password`, { password, token })
      .then((e) => {
        return e.data.message;
      });
  }

  public async logout(): Promise<string> {
    return await this.restricted()
      .get<{ message: string }>(`/user/logout`)
      .then((e) => {
        this._user = null;
        this.auth = null;
        return e.data.message;
      });
  }

  public async reconnectUser(): Promise<void> {
    if (this.isTokenValid()) {
      if (this._user) return await Promise.resolve();

      return await this.restricted()
        .get<iUserRest>(`/user/refreshToken`)
        .then((e) => e.data)
        .then((userRest) => {
          this.setUser(userRest);
        })
        .catch((error) => {
          throw new Error(
            error.response.data.message ||
              `${error.response.status} | Unknow error...`
          );
        });
    }

    this._user = null;

    return undefined;
  }

  public getUser(): iUser | null {
    if (!this.isTokenValid()) return null;

    return this._user;
  }

  private setUser(user: iUserRest): iUser {
    this.auth = {
      token: user.token,
      token_type: user.token_type,
      expires_in: user.expires_in,
      expires_at: user.expires_at,
    };

    this._user = {
      email: user.email,
      role: user.role,
      firstname: user.firstname,
      lastname: user.lastname,
      phone: user.phone,
      phone_port: user.phone_port,
    };

    return this._user;
  }

  public async updateUser(
    {
      email,
      firstname,
      lastname,
      phone,
      phone_port,
    }: {
      email: string;
      firstname: string;
      lastname: string;
      phone: string;
      phone_port: string;
    },
    userId: number
  ): Promise<iUser | null> {
    return await this.restricted()
      .put<iUserRest>(`/user/${userId}/update`, {
        email,
        firstname,
        lastname,
        phone,
        phone_port,
      })
      .then((e) => e.data)
      .then((userRest) => {
        if (!this._user) {
          this.auth = null;
          throw new Error(`Unknow error...`);
        }
        this._user = {
          email: userRest.email,
          role: this._user?.role,
          firstname: userRest.firstname,
          lastname: userRest.lastname,
          phone: userRest.phone,
          phone_port: userRest.phone_port,
        };
        return this._user;
      })
      .catch((error) => {
        throw new Error(
          error.response.data.message ||
            JSON.stringify(error.response.data)
              .replace(/[{}[\]"]/g, '')
              .replace(/,/g, '<br/>') ||
            `${error.response.status} | Unknow error...`
        );
      });
  }

  public async updateUserActive(
    status: boolean,
    userId: number
  ): Promise<boolean> {
    return await this.restricted()
      .put<iUserRest>(`/user/${userId}/update/active`, { status })
      .then((e) => !!e.data)
      .catch((error) => {
        throw new Error(
          error.response.data.message ||
            JSON.stringify(error.response.data)
              .replace(/[{}[\]"]/g, '')
              .replace(/,/g, '<br/>') ||
            `${error.response.status} | Unknow error...`
        );
      });
  }

  public async remove(userId: number): Promise<boolean> {
    return await this.restricted()
      .delete<boolean>(`/user/${userId}`)
      .then((e) => !!e.data)
      .catch((error) => {
        throw new Error(
          error.response.data.message ||
            JSON.stringify(error.response.data)
              .replace(/[{}[\]"]/g, '')
              .replace(/,/g, '<br/>') ||
            `${error.response.status} | Unknow error...`
        );
      });
  }
}
