/* eslint-disable react/require-default-props */
/* eslint-disable react/jsx-props-no-spreading */
import React, {
  ForwardRefExoticComponent,
  PropsWithoutRef,
  RefAttributes,
} from 'react';
import type { iIcons } from '../Atoms/Icon/Icons.type';
import './color.scss';
import './sizing.scss';
import './position.scss';
import './spacing.scss';
import './text.scss';
import './border.scss';
import './arrow.scss';
import './transform.scss';
import './icon.scss';

type width = 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 15 | 20 | 30 | 40 | 50;
type greys = '05' | 10 | 20 | 30 | 40 | 50 | 60 | 70 | 80 | 90;
type opacity =
  | '0'
  | '10'
  | '20'
  | '30'
  | '40'
  | '50'
  | '60'
  | '70'
  | '80'
  | '90'
  | '100';
type opacities =
  | '004'
  | '008'
  | '010'
  | '020'
  | '030'
  | '040'
  | '050'
  | '060'
  | '070'
  | '080'
  | '090';
export type tColors =
  | 'primary'
  | `primary-${opacities}`
  | 'secondary'
  | `secondary-${opacities}`
  | 'tertiary'
  | `tertiary-${opacities}`
  | 'alert'
  | 'warning'
  | 'info'
  | 'success'
  | 'white'
  | `white-${opacities}`
  | 'black'
  | 'grey-cold'
  | 'grey-medium'
  | 'grey-light'
  | 'grey-ultra-light'
  | `grey-${greys}`
  | 'transparent'
  | 'prowein-red'
  | 'prowein-blue-dark'
  | 'prowein-blue-light'
  | 'prowein-brown';
type positiveSpacers =
  | 0
  | 1
  | 2
  | 3
  | 4
  | 5
  | 10
  | 15
  | 20
  | 25
  | 30
  | 35
  | 40
  | 45
  | 50
  | 60
  | 70
  | 80
  | 90
  | 100;
type negativeSpacers = `-${positiveSpacers}`;
type spacers = positiveSpacers | negativeSpacers;
type sizes = 25 | 50 | 75 | 100;
type fontSizes = 14 | 16 | 18 | 22 | 24 | 32 | 48;
type angles = 45 | 90 | 135 | 180;

export interface iSpacing {
  m?: `${spacers}` | 'auto';
  mx?: `${spacers}` | 'auto';
  my?: `${spacers}` | 'auto';
  mt?: `${spacers}` | 'auto';
  mr?: `${spacers}` | 'auto';
  mb?: `${spacers}` | 'auto';
  ml?: `${spacers}` | 'auto';
  p?: `${positiveSpacers}`;
  px?: `${positiveSpacers}`;
  py?: `${positiveSpacers}`;
  pt?: `${positiveSpacers}`;
  pr?: `${positiveSpacers}`;
  pb?: `${positiveSpacers}`;
  pl?: `${positiveSpacers}`;
}

export interface iSizing {
  w?:
    | `${sizes}`
    | `${sizes}vw`
    | 'auto'
    | 'xs'
    | 'button'
    | 'small'
    | 'medium'
    | 'large';
  h?:
    | `${sizes}`
    | `${sizes}vh`
    | 'auto'
    | 'xs'
    | 'button'
    | 'small'
    | 'medium'
    | 'large';
  mw?: `${sizes}` | 'small' | 'normal' | 'medium' | 'large' | 'xlarge';
  mh?: `${sizes}`;
  minw?: `${sizes}` | 'small' | 'medium' | 'large' | 'xlarge';
  minh?: `${sizes}`;
}

export interface iPosition {
  top?: '0' | '25' | '50' | '75' | '100';
  left?: '0' | '25' | '50' | '75' | '100';
  position?: 'relative' | 'absolute' | 'fixed' | 'sticky';
}
export interface iText {
  fontSize?: `${fontSizes}`;
  color?: tColors;
  textAlign?: 'start' | 'center' | 'end' | 'justify';
  textTransform?: 'lowercase' | 'uppercase' | 'capitalize';
  fw?: 'normal' | 'bold' | 'black';
  fst?: 'italic' | 'normal';
  textDecoration?: 'underline' | 'none';
  whiteSpace?: 'nowrap' | 'normal';
  wordBreak?: 'breakAll' | 'breakWord' | 'keepAll' | 'normal';
}

export interface iColors {
  bg?: tColors;
  opacity?: opacity;
}
export interface iIcon {
  iconBefore?: iIcons;
  iconAfter?: iIcons;
}

export interface iStyle {
  className?: string;
}

export interface iBorder {
  borderColor?: tColors;
  borderWidth?: `${width}`;
  borderStyle?: 'solid' | 'dotted' | 'dashed';
  borderRadius?: `${width}`;
  overflow?: 'hidden' | 'scroll' | 'visible' | 'auto';
}
export interface iArrow {
  arrowColor?: tColors;
  arrowBg?: tColors;
  arrowDirection?: 'top' | 'right' | 'bottom' | 'left';
}

export interface iTransform {
  rotate?: `${'r' | 'l'}${angles}`;
}

export const join = (
  ...classes: Array<string[] | string | undefined | boolean>
): string => classes.flat().filter(Boolean).join(' ');

export function withStyle<
  T extends iStyle &
    iSpacing &
    iSizing &
    iText &
    iColors &
    iBorder &
    iArrow &
    iTransform &
    iIcon
>(
  WrappedComponent: React.ComponentType<T>
): ForwardRefExoticComponent<
  PropsWithoutRef<T & iStyle> & RefAttributes<unknown>
> {
  const displayName =
    WrappedComponent.displayName ?? (WrappedComponent.name || 'Component');

  const ComponentWithStyle = (
    props: T & iStyle,
    ref: React.ForwardedRef<unknown>
  ): JSX.Element => {
    const { className = '' } = props;
    const {
      m,
      mx,
      my,
      mt,
      mr,
      mb,
      ml,
      p,
      px,
      py,
      pt,
      pr,
      pb,
      pl,
      w,
      h,
      mw,
      mh,
      minw,
      minh,
      fontSize,
      color,
      textAlign,
      textTransform,
      fw,
      fst,
      textDecoration,
      bg,
      opacity,
      borderColor,
      borderWidth,
      borderStyle,
      borderRadius,
      overflow,
      rotate,
      iconBefore,
      iconAfter,
      arrowColor,
      arrowBg,
      arrowDirection,
      whiteSpace,
      wordBreak,
      ...etc
    } = props;

    const styleClassName: string[] = [];

    if (m) styleClassName.push(`m${m}`);
    if (mx) styleClassName.push(`mx${mx}`);
    if (my) styleClassName.push(`my${my}`);
    if (mt) styleClassName.push(`mt${mt}`);
    if (mr) styleClassName.push(`mr${mr}`);
    if (mb) styleClassName.push(`mb${mb}`);
    if (ml) styleClassName.push(`ml${ml}`);

    if (p) styleClassName.push(`p${p}`);
    if (px) styleClassName.push(`px${px}`);
    if (py) styleClassName.push(`py${py}`);
    if (pt) styleClassName.push(`pt${pt}`);
    if (pr) styleClassName.push(`pr${pr}`);
    if (pb) styleClassName.push(`pb${pb}`);
    if (pl) styleClassName.push(`pl${pl}`);

    if (w) styleClassName.push(`w${w}`);
    if (h) styleClassName.push(`h${h}`);

    if (mw) styleClassName.push(`mw${mw}`);
    if (mh) styleClassName.push(`mh${mh}`);

    if (minw) styleClassName.push(`minw${minw}`);
    if (minh) styleClassName.push(`minh${minh}`);

    if (fontSize) styleClassName.push(`fs${fontSize}`);
    if (color) styleClassName.push(`color${color}`);
    if (textAlign) styleClassName.push(`textAlign${textAlign}`);
    if (textTransform) styleClassName.push(`textTransform${textTransform}`);
    if (fw) styleClassName.push(`fw${fw}`);
    if (fst) styleClassName.push(`fst${fst}`);
    if (textDecoration) styleClassName.push(`textDecoration${textDecoration}`);

    if (bg) styleClassName.push(`bg${bg}`);
    if (opacity) styleClassName.push(`opacity${opacity}`);

    if (borderColor) styleClassName.push(`borderColor${borderColor}`);
    if (borderWidth) styleClassName.push(`borderWidth${borderWidth}`);
    if (borderStyle) styleClassName.push(`borderStyle${borderStyle}`);
    if (borderRadius) styleClassName.push(`borderRadius${borderRadius}`);
    if (overflow) styleClassName.push(`overflow${overflow}`);

    if (arrowColor) styleClassName.push(`arrowColor${arrowColor}`);
    if (arrowBg) styleClassName.push(`arrowBg${arrowBg}`);
    if (arrowDirection) styleClassName.push(`arrowDirection${arrowDirection}`);

    if (rotate) styleClassName.push(`rotate${rotate}`);

    if (iconBefore) styleClassName.push(`iconBefore${iconBefore}`);
    if (iconAfter) styleClassName.push(`iconAfter${iconAfter}`);

    if (whiteSpace) styleClassName.push(`whiteSpace${whiteSpace}`);
    if (wordBreak) styleClassName.push(`wordBreak${wordBreak}`);

    return (
      <WrappedComponent
        {...(etc as T)}
        fontSize={fontSize}
        ref={ref}
        className={join(className, styleClassName)}
      />
    );
  };

  ComponentWithStyle.displayName = `withStyle(${displayName})`;

  return React.forwardRef(ComponentWithStyle);
}
