import React from 'react';
import {
  iBorder,
  iColors,
  iSpacing,
  iStyle,
  join,
  withStyle,
} from '../../_HOC/withStyle';
import styles from './Alert.module.scss';

export interface iAlert extends iStyle, iColors, iSpacing, iBorder {
  children: React.ReactNode;
  side?: React.ReactNode;
  alert: 'success' | 'info' | 'warning' | 'error';
}

export const Alert = withStyle(
  ({ children, side, alert, className }: iAlert) => {
    if (!children) return null;
    return (
      <div
        className={join(styles.Alert, className)}
        data-alert={alert}
        // style={stylesUtilities}
        role="alert"
      >
        <div className={styles.Alert__side}>{side}</div>
        <div className={styles.Alert__content}>{children}</div>
      </div>
    );
  }
);
