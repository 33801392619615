export default async function packageRequest<T>(
  fn: (e: string) => Promise<T>,
  param: string[],
  progress?: (e: number) => void
): Promise<T[]> {
  const packages: string[][] = param.reduce((acc: any, e: any) => {
    const lastIndex = acc.length - 1;
    if (!acc[lastIndex] || acc[lastIndex].length >= 10) acc.push([e]);
    else acc[lastIndex].push(e);
    return acc;
  }, []);

  let result: any[] = [];
  const packLength = packages.length;
  let loopContinue = packLength;

  while (loopContinue) {
    loopContinue -= 1;

    result = [
      ...result,
      ...(await Promise.all(
        packages[loopContinue].map(async (e) => await fn(e))
      )),
    ];

    if (progress) progress((packLength - loopContinue) / packLength);
  }

  return result as T[];
}
