import React, { useState } from 'react';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import useApiContext from 'hooks/useApiContext';
import Form, { InputPhone, InputText } from 'form';
import { T, O, M, A } from 'TOMA';
import formatPhone from 'helpers/format';

export interface iFormSignUp {
  firstname: string;
  lastname: string;
  phone: string;
  raison_sociale: string;
  siret: string;
  email: string;
  password: string;
  passwordConfirmation: string;
}

function SignUp(): JSX.Element {
  const { userRepository } = useApiContext();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string>();
  const [success, setSuccess] = useState<string | undefined>();

  const schema = yup.object({
    firstname: yup.string().required('This field is required'),
    lastname: yup.string().required('This field is required'),
    raison_sociale: yup.string().required('This field is required'),
    siret: yup.string().required('This field is required'),
    email: yup
      .string()
      .email('Please enter a valid email')
      .required('This field is required'),
    password: yup
      .string()
      .pipe(
        yup
          .string()
          .min(8, '<b>8 characters</b>')
          .minLowercase(1, '<b>one lowercase</b>')
          .minUppercase(1, '<b>one uppercase</b>')
          .minNumbers(1, '<b>one number</b>'),
        `The password must contain at least \${items}.`,
        'and'
      )
      .required('This field is required'),
    passwordConfirmation: yup
      .string()
      .oneOf([yup.ref('password'), null], 'Passwords must match')
      .required('This field is required'),
  });

  function handleSubmit({
    firstname,
    lastname,
    phone,
    raison_sociale,
    siret,
    email,
    password,
  }: iFormSignUp): void {
    setLoading(true);
    userRepository
      .register(
        firstname,
        lastname,
        phone,
        raison_sociale.trim(),
        siret,
        email,
        password
      )
      .then((e) => {
        setSuccess(
          "Vous allez recevoir un e-mail d'ici quelques minutes pour confirmer votre compte."
        );
      })
      .catch((err: Error) => {
        setError(err.message);
      })
      .finally(() => setLoading(false));
  }

  return (
    <T.Container py="50">
      <T.Block pb="50">
        <A.Title as="h1" fontSize="48" textAlign="center" fw="black">
          Plateforme d’enregistrement e-catalogue du Pavillon France
        </A.Title>
      </T.Block>
      <T.Block pb="50">
        <A.Title as="h1" fontSize="32" textAlign="center" fw="black">
          Demande de création de compte :
        </A.Title>
      </T.Block>
      <T.Block bg="grey-10" borderRadius="5" mw="medium" mx="auto" p="80">
        {!success && (
          <>
            <T.Block pb="50">
              <A.Text fontSize="16">
                <b>Contact pour la création des logins &gt;</b> Information non
                visible** sur les supports de communication
              </A.Text>
            </T.Block>
            <Form<iFormSignUp>
              onSubmit={async (data) => handleSubmit(data)}
              resolver={yupResolver(schema)}
            >
              {({ control }) => (
                <div>
                  <T.Block mb="40">
                    <InputText
                      label="Prénom"
                      name="firstname"
                      type="text"
                      control={control}
                      required
                    />
                    <InputText
                      label="Nom"
                      name="lastname"
                      type="text"
                      control={control}
                      required
                    />
                    <InputPhone
                      label="Téléphone"
                      name="phone"
                      control={control}
                      placeholder="+33 1 23 45 67 88"
                    />
                    <InputText
                      label="Raison Sociale"
                      name="raison_sociale"
                      type="text"
                      control={control}
                      required
                    />
                    <InputText
                      label="Siret"
                      name="siret"
                      type="text"
                      control={control}
                      required
                    />
                    <InputText
                      label="Email / login"
                      name="email"
                      type="text"
                      control={control}
                      required
                    />
                    <InputText
                      label="Choisir votre mot de passe"
                      name="password"
                      type="password"
                      control={control}
                      required
                      autocomplete="new-password"
                    />
                    <InputText
                      label="Confirmer votre mot de passe"
                      name="passwordConfirmation"
                      type="password"
                      control={control}
                      required
                      autocomplete="new-password"
                    />
                  </T.Block>

                  <A.Alert alert="error" side="⚠️" mb="30">
                    {error && <A.InnerHTML html={error} />}
                  </A.Alert>

                  <A.Button type="submit" loading={loading} center>
                    Continuer
                  </A.Button>
                </div>
              )}
            </Form>
            <A.Text fontSize="14">* Information obligatoire</A.Text>
            <A.Text fontSize="14">
              ** Les informations demandées ci-dessus servent à la création du
              compte et des logins de connexion pour votre entreprise. Ces
              informations ne seront pas visibles sur le e-catalogue, elles sont
              conservées pour la gestion du dossier. (1 adresse e-mail = 1
              compte entreprise)
            </A.Text>
          </>
        )}

        {success && (
          <>
            <A.Text fontSize="48" textAlign="center" mb="30">
              🎉
            </A.Text>
            <A.Text fontSize="18" textAlign="center" fw="bold">
              {success}
            </A.Text>
          </>
        )}
      </T.Block>
    </T.Container>
  );
}

export default SignUp;
