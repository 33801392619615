import React from 'react';
import {
  iBorder,
  iColors,
  iSpacing,
  iStyle,
  iText,
  iTransform,
  join,
  withStyle,
} from '../../_HOC/withStyle';
import type { iIcons } from './Icons.type';

/* https://icons.getbootstrap.com */
interface iIcon extends iStyle, iSpacing, iColors, iText, iBorder, iTransform {
  icon?: iIcons;
}

export const Icon = withStyle(({ icon, className }: iIcon) => {
  if (icon === undefined) return null;
  return <i className={join(`bi bi-${icon}`, className, className)} />;
});
