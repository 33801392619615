import React, { ReactNode } from 'react';
import {
  iBorder,
  iArrow,
  iColors,
  iSizing,
  iSpacing,
  iStyle,
  iTransform,
  withStyle,
  join,
} from '../../_HOC/withStyle';
import style from './BlockResponsive.module.scss';

interface iBlockResponsive
  extends iStyle,
    iColors,
    iArrow,
    iSpacing,
    iSizing,
    iBorder,
    iTransform {
  children: ReactNode;
}

function BlockResponsiveComp({
  children,
  className,
}: iBlockResponsive): JSX.Element {
  return (
    <div className={join(className, style.BlockResponsive)}>{children}</div>
  );
}

export const BlockResponsive = withStyle(BlockResponsiveComp);
