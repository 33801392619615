/*

exemples :
routes.test.path;    // -> '/test/:id/truc/:token/test'
routes.test.to({ id: 'xxxx', token: 32 });    // -> '/test/xxxx/truc/32/test'

*/

type tPath = `/${string}`;

function createRoute<T>(path: tPath): {
  path: tPath;
  to: (args?: T) => tPath;
} {
  return {
    path,
    to: (args) => {
      if (!args) return path;
      return Object.entries(args).reduce(
        (acc, [key, value]) => acc.replace(`:${key}`, value as string) as tPath,
        path
      );
    },
  };
}

const routes = {
  home: createRoute('/'),
  login: createRoute('/login'),
  signup: createRoute('/signup'),
  forgotPassword: createRoute('/forgot-password'),
  exhibitor: createRoute<{ exhibitorId: string | number }>(
    '/exhibitor/:exhibitorId'
  ),
  product: createRoute('/product'),
  productId: createRoute<{ productId: string | number }>('/product/:productId'),
  productAdmin: createRoute<{ exhibitorId?: string | number }>(
    '/exhibitor/:exhibitorId/product'
  ),
  productIdAdmin: createRoute<{
    exhibitorId?: string | number;
    productId: string | number;
  }>('/exhibitor/:exhibitorId/product/:productId'),
  codif: createRoute('/codif'),
  confirmation: createRoute<{ confirmationId: string }>(
    '/confirmation/:confirmationId'
  ),
  updatePassword: createRoute<{ updatePasswordId: string }>(
    '/update-password/:updatePasswordId'
  ),

  /* test1: createRoute('/test'),
  test: createRoute<{ id: string; token: number }>(
    '/test/:id/truc/:token/test'
  ), */
};

export default routes;
