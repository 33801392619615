import React from 'react';
import { A } from '..';
import styles from './InputSwitch.module.scss';

export interface iInputSwitch {
  label?: React.ReactNode;
  name?: string;
  id?: string;
  checked?: boolean;
  defaultChecked?: boolean;
  disabled?: boolean;
  error?: React.ReactNode;
  loading?: boolean;
  onChange?: (e: boolean) => void;
}

export const InputSwitch = React.forwardRef(function field(
  {
    label,
    name,
    id,
    checked,
    defaultChecked,
    disabled,
    loading,
    onChange,
    error,
  }: iInputSwitch,
  ref: React.ForwardedRef<HTMLInputElement>
) {
  return (
    <div className={styles.InputSwitch}>
      <label className={styles.InputSwitch__group} htmlFor={id ?? name}>
        <input
          type="checkbox"
          checked={checked}
          defaultChecked={defaultChecked}
          name={name}
          id={id ?? name}
          className={styles.InputSwitch__field}
          disabled={disabled}
          onChange={({ target }) => {
            if (onChange) onChange(target.checked);
          }}
          ref={ref}
        />
        <span
          className={styles.Checkbox__status}
          data-disabled={disabled}
          data-error={!!error}
        >
          <span>ON</span>
          <span>OFF</span>
        </span>
      </label>

      {loading && 'loading'}

      <A.Text as="div" mt="5" fontSize="14" color="alert">
        {error}
      </A.Text>
    </div>
  );
});

InputSwitch.defaultProps = {
  checked: undefined,
  id: undefined,
  defaultChecked: undefined,
  disabled: undefined,
  onChange: undefined,
  error: undefined,
};
