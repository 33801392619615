import React from 'react';
import styles from './Modal.module.scss';
import { A } from '../..';

export function Modal({
  content,
  visible,
  setVisible,
}: {
  content?: React.ReactNode;
  visible: boolean;
  setVisible: (visible: boolean) => void;
}): JSX.Element {
  return (
    <div className={styles.Modal} data-visible={!!visible}>
      <div className={styles.Modal__back} onClick={() => setVisible(false)} />
      <div className={styles.Modal__content}>
        <button
          type="button"
          onClick={() => setVisible(false)}
          className={styles.Modal__close}
        >
          <A.Icon icon="x-lg" fontSize="24" color="black" />
        </button>
        {content}
      </div>
    </div>
  );
}
