import { Breadcrumb } from './Breadcrumb';
import { Extend } from './Extend';
import { Loading } from './Loading';
import { Modal } from './Modal';

export const M = {
  Breadcrumb,
  Extend,
  Loading,
  Modal,
};
