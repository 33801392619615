import React, { useState } from 'react';
import { A } from '..';
import { iIcons } from '../Icon/Icons.type';
import styles from './InputNumber.module.scss';

export interface iInputNumber {
  name: string;
  id?: string;
  value?: number;
  min?: number;
  max?: number;
  disabled?: boolean;
  required?: boolean;
  placeholder?: string;
  label?: string;
  error?: React.ReactNode;
  onChange?: (e: React.FocusEvent<HTMLInputElement>) => void;
  onBlur?: (e: React.FocusEvent<HTMLInputElement>) => void;
  information?: React.ReactNode;
}

export const InputNumber = React.forwardRef(function field(
  {
    name,
    id,
    value,
    min,
    max,
    disabled,
    required,
    placeholder,
    onChange,
    onBlur,
    label,
    error,
    information,
  }: iInputNumber,
  ref: React.ForwardedRef<HTMLInputElement>
) {
  return (
    <div className={styles.InputNumber}>
      {label && (
        <label
          htmlFor={id ?? name}
          className={styles.InputNumber__label}
          data-error={!!error}
          data-disabled={disabled}
        >
          {label}
          {required && <i data-asterix>*</i>}
        </label>
      )}
      {information && (
        <A.Text
          as="div"
          mb="10"
          mt="-3"
          fontSize="14"
          color={disabled ? 'grey-20' : 'grey-50'}
        >
          {information}
        </A.Text>
      )}
      <div className={styles.InputNumber__group}>
        <input
          name={name}
          type="number"
          value={value}
          min={min}
          max={max}
          inputMode="numeric"
          disabled={disabled}
          placeholder={placeholder}
          onChange={onChange}
          onBlur={onBlur}
          className={styles.InputNumber__field}
          required={required}
          data-error={!!error}
          id={id ?? name}
          ref={ref}
        />
      </div>

      <A.Text as="div" mt="5" fontSize="14" color="alert">
        {error}
      </A.Text>
    </div>
  );
});

InputNumber.defaultProps = {
  id: undefined,
  value: undefined,
  label: undefined,
  min: undefined,
  max: undefined,
  disabled: undefined,
  required: undefined,
  placeholder: undefined,
  onChange: undefined,
  onBlur: undefined,
  error: undefined,
  information: undefined,
};
