import React from 'react';
import {
  useController,
  FieldValues,
  Control,
  UseControllerProps,
} from 'react-hook-form';
import { A } from 'TOMA';

type iInputCheckbox<T extends FieldValues> = {
  label: React.ReactNode;
  name: string;
  id?: string;
  control: Control<T>;
  required?: string | boolean;
  disabled?: boolean;
} & UseControllerProps<T>;

export function InputCheckbox<T extends FieldValues>({
  label,
  name,
  id,
  control,
  disabled,
}: iInputCheckbox<T>): JSX.Element {
  const {
    field: { onChange, value, ref },
    formState: { errors, isSubmitted },
  } = useController({
    name,
    control,
  });

  const path = name.split('.');
  let key;
  let error: any = errors;
  while ((key = path.shift())) {
    if (!Number.isNaN(parseInt(key, 10))) {
      key = parseInt(key, 10);
    }
    if (!error[key]) {
      error = undefined;
      break;
    }
    error = error[key];
  }
  const errorMessage = isSubmitted && error && error.message;

  return (
    <A.InputCheckbox
      name={name}
      id={id}
      checked={value}
      onChange={onChange}
      ref={ref}
      disabled={disabled}
      label={label}
      error={errorMessage}
    />
  );
}
InputCheckbox.defaultProps = {
  id: undefined,
  required: undefined,
};
