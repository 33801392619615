import React, { useState } from 'react';
import { T } from 'TOMA/Templates';
import { A } from '..';
import styles from './InputTextarea.module.scss';

export interface iInputTextarea {
  name: string;
  id?: string;
  value?: string;

  disabled?: boolean;
  required?: boolean;
  placeholder?: string;
  label?: string;
  rows?: number;
  counter?: number;
  error?: React.ReactNode;
  onChange?: (e: React.FocusEvent<HTMLTextAreaElement>) => void;
  onBlur?: (e: React.FocusEvent<HTMLTextAreaElement>) => void;
}

export const InputTextarea = React.forwardRef(function field(
  {
    name,
    id,
    value,
    disabled,
    required,
    placeholder,
    onChange,
    onBlur,
    label,
    rows,
    counter,
    error,
  }: iInputTextarea,
  ref: React.ForwardedRef<HTMLTextAreaElement>
) {
  return (
    <div className={styles.InputTextarea}>
      {label && (
        <label
          htmlFor={id ?? name}
          className={styles.InputTextarea__label}
          data-error={!!error}
          data-disabled={disabled}
        >
          {label}
          {required && <i data-asterix>*</i>}
        </label>
      )}
      <div className={styles.InputTextarea__group}>
        <textarea
          name={name}
          value={value}
          disabled={disabled}
          placeholder={placeholder}
          onChange={onChange}
          onBlur={onBlur}
          className={styles.InputTextarea__field}
          required={required}
          data-error={!!error}
          id={id ?? name}
          rows={rows}
          ref={ref}
        />
      </div>
      <T.Flex valign="baseline" halign="space-between">
        <A.Text as="div" mt="5" fontSize="14" color="alert">
          {error}
        </A.Text>
        {counter && (
          <A.Text
            fontSize="14"
            color={
              (value?.length ?? 0) > counter || error ? 'alert' : 'grey-50'
            }
            mt="2"
          >
            {value?.length ?? 0}/{counter}
          </A.Text>
        )}
      </T.Flex>
    </div>
  );
});

InputTextarea.defaultProps = {
  id: undefined,
  value: undefined,
  label: undefined,
  disabled: undefined,
  required: undefined,
  placeholder: undefined,
  onChange: undefined,
  onBlur: undefined,
  error: undefined,
};
