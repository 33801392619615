import React, { useEffect, useState } from 'react';
import { useLocation, useParams, Outlet, Link } from 'react-router-dom';
import useApiContext from 'hooks/useApiContext';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import useRepo from 'hooks/useRepo';
import useModalContext from 'hooks/useModalContext';
import { T, O, M, A } from 'TOMA';
import {
  InputText,
  InputNumber,
  InputSelect,
  InputTextarea,
  InputSlider,
  InputFile,
  InputMedias,
  InputRadio,
  InputAutocomplete,
  InputPhone,
  InputCheckbox,
} from 'form';
import { CustomLink } from 'components/CustomLink';
import { eRoles } from 'interfaces/Roles';
import s from './Exhibitor.module.scss';
import { format } from 'date-fns';

export interface iForm {
  firstname: string;
  lastname: string;
  phone: string;
  phone_port: string;
  email: string;

  stand_type: string;
  collectif_type: string;

  raison_sociale: string;
  nom_societe: string;
  siret: string;
  tel_societe: string;
  email_societe: string;
  site_web: string;
  adresse_postale: string;
  ville: string;
  code_postal: string;
  pays: string;
  description_fr: string;
  description_en: string;
  contact_revendeur_firstname: string;
  contact_revendeur_lastname: string;
  contact_revendeur_port: string;
  contact_revendeur_tel: string;
  contact_revendeur_email: string;
  logo: string;
  logo_hd: string;
  type_entreprise: string;
  twitter: string;
  linkedin: string;
  instagram: string;
  facebook: string;
  pourcentage_export: string;
  pays_export: string[];
  pays_import: string[];
  youtube_video: string;
  production_annuelle: number;
  taille_domaine: number;
  media_1: string;
  media_2: string;
  media_3: string;
  media_4: string;
  media_5: string;
  locked: number;
  rgpd: boolean;
}

function Exhibitor(): JSX.Element {
  const { setModalContent } = useModalContext();
  const { exhibitorId } = useParams();
  const {
    exhibitorRepository,
    userRepository,
    mediaRepository,
    codificationRepository,
    preferenceRepository,
  } = useApiContext();
  const { pathname } = useLocation();
  const user = userRepository.getUser();
  const [loadingForm, setLoadingForm] = useState(false);
  const [successForm, setSuccessForm] = useState(false);
  const [errorForm, setErrorForm] = useState<string>();
  const isAdmin = user?.role === eRoles.ADMIN;

  const { response, loading, error, setResponse } = useRepo(
    async () => await exhibitorRepository.getUserExhibitor(exhibitorId),
    []
  );
  const { response: products, loading: loadingProduct } = useRepo(
    async () =>
      await exhibitorRepository
        .getUserExhibitor(exhibitorId)
        .then((e) => e.products),
    [pathname]
  );
  const { response: codifTypeEntreprise } = useRepo(
    async () => await codificationRepository.get('type_entreprise')
  );
  const { response: codifPourcentage } = useRepo(
    async () => await codificationRepository.get('pourcentage')
  );
  const { response: codifPays } = useRepo(
    async () => await codificationRepository.get('pays')
  );
  const { response: codifCollectif } = useRepo(
    async () => await codificationRepository.get('collectif')
  );
  const { response: dateLimit, loading: loadingDatelimit } = useRepo(
    async () => await preferenceRepository.find('date_limit')
  );

  const dtLimit = dateLimit ? new Date(dateLimit) : null;
  const dtLimitFrench = dtLimit
    ? format(new Date(dtLimit), 'dd/MM/yyyy')
    : null;

  const schema = yup.object({
    firstname: yup
      .string()
      .max(255)
      .required('This field is required')
      .nullable(),
    lastname: yup
      .string()
      .max(255)
      .required('This field is required')
      .nullable(),
    email: yup
      .string()
      .max(255)
      .email('Please enter a valid email')
      .required('This field is required')
      .nullable(),

    adresse_postale: yup
      .string()
      .max(255)
      .required('This field is required')
      .nullable(),
    ville: yup.string().max(63).required('This field is required').nullable(),
    code_postal: yup
      .string()
      .max(15)
      .required('This field is required')
      .nullable(),
    pays: yup.string().max(63).required('This field is required').nullable(),
    site_web: yup
      .string()
      .max(255)
      .required('This field is required')
      .nullable(),
    siret: yup.string().max(127).required('This field is required').nullable(),
    description_fr: yup
      .string()
      .max(500)
      .required('This field is required')
      .nullable(),
    description_en: yup
      .string()
      .max(500)
      .required('This field is required')
      .nullable(),

    logo: yup.string().required('This field is required').nullable(),

    twitter: yup.string().max(255).nullable(),
    linkedin: yup.string().max(255).nullable(),
    instagram: yup.string().max(255).nullable(),
    facebook: yup.string().max(255).nullable(),
    youtube_video: yup.string().max(255).nullable(),

    email_societe: yup
      .string()
      .max(127)
      .email('Please enter a valid email')
      .nullable(),
    contact_revendeur_email: yup
      .string()
      .max(124)
      .email('Please enter a valid email')
      .nullable(),
    stand_type: yup.string().required('This field is required').nullable(),

    collectif_type: yup.string().when('stand_type', {
      is: 'collectif',
      then: yup.string().required('This field is required').nullable(),
      otherwise: yup.string().nullable(),
    }),
    raison_sociale: yup
      .string()
      .max(127)
      .required('This field is required')
      .nullable(),
    nom_societe: yup
      .string()
      .max(127)
      .required('This field is required')
      .nullable(),
    type_entreprise: yup.string().required('This field is required').nullable(),
    rgpd: yup.boolean().oneOf([true], 'This field is required').nullable(),
  });

  const form = useForm<iForm>({
    mode: 'onChange',
    reValidateMode: 'onBlur',
    resolver: yupResolver(schema),
    defaultValues: {
      ...response?.exhibitor,
      ...response?.user,
      pays_export: response?.exhibitor?.pays_export?.split(';') ?? [],
      pays_import: response?.exhibitor?.pays_import?.split(';') ?? [],
      rgpd: response?.exhibitor?.rgpd === 1,
    },
  });
  const { handleSubmit, watch, control, reset, setValue, getValues } = form;

  useEffect(() => {
    if (response) {
      reset({
        ...response?.exhibitor,
        ...response?.user,
        pays_export: response?.exhibitor?.pays_export?.split(';') ?? [],
        pays_import: response?.exhibitor?.pays_import?.split(';') ?? [],
        rgpd: response?.exhibitor?.rgpd === 1,
      });
    }
  }, [response]);

  function onSubmit(data: iForm): void {
    setLoadingForm(true);

    if (!response) {
      setErrorForm('Erreur inconnue...');
      setLoadingForm(false);
      return;
    }
    Promise.all([
      userRepository
        .updateUser(data, response.user.id)
        .finally(() => setLoadingForm(false)),
      exhibitorRepository
        .updateUserExhibitor(
          {
            ...data,
            raison_sociale: data.raison_sociale.trim(),
            nom_societe: data.nom_societe.trim(),
            pays_export: data.pays_export.join(';'),
            pays_import: data.pays_import.join(';'),
            rgpd: data.rgpd ? 1 : 0,
          },
          response.exhibitor.id
        )
        .then((data) => {
          setResponse((state) =>
            state
              ? {
                  exhibitor: { ...state.exhibitor, ...data.exhibitor },
                  products: state.products,
                  user: state.user,
                }
              : state
          );
        }),
    ])
      .then(() => {
        setSuccessForm(true);
        setErrorForm('');
      })
      .catch((err: Error) => {
        setErrorForm(err.message);
      })
      .finally(() => setLoadingForm(false));
  }

  const disabled = !isAdmin && !!response?.exhibitor.locked;

  const mediaList: Array<
    'media_1' | 'media_2' | 'media_3' | 'media_4' | 'media_5'
  > = ['media_1', 'media_2', 'media_3', 'media_4', 'media_5'];

  const stand_type = watch('stand_type');
  const raisonSociale = watch('raison_sociale');

  return (
    <div>
      <div className={pathname !== '/' && !isAdmin ? s.blur : undefined}>
        <T.Container pb="50" pt="20">
          <M.Loading
            loading={loading && loadingDatelimit}
            error={error}
            fullWiewport
          >
            <M.Breadcrumb breadcrumb={[{ label: raisonSociale, path: '/' }]} />
            <T.Block pb="50" mw="large" m="auto">
              <A.Title as="h1" fontSize="48" fw="black">
                Enregistrement des données
              </A.Title>
              <A.Text mb="30">
                Date limite d&apos;enregistrement au e-catalogue :{' '}
                {dtLimitFrench}
              </A.Text>

              <T.Block
                pb="50"
                mw="medium"
                m="auto"
                bg="grey-05"
                p="20"
                borderRadius="7"
              >
                <form onSubmit={handleSubmit(onSubmit)} noValidate>
                  <T.Block>
                    <A.Title as="h2" fontSize="24" mb="20" fw="black">
                      Contact pour ce dossier
                      <A.Text
                        as="div"
                        fontSize="14"
                        fw="normal"
                        color="grey-50"
                      >
                        Information non visible sur les supports de
                        communication
                      </A.Text>
                    </A.Title>
                    <A.Line mb="20" />
                    <T.BlockResponsive>
                      <InputText
                        label="Prénom"
                        name="firstname"
                        type="text"
                        control={control}
                        disabled={disabled}
                        required
                      />
                      <InputText
                        label="Nom"
                        name="lastname"
                        type="text"
                        control={control}
                        disabled={disabled}
                        required
                      />

                      <InputPhone
                        label="Téléphone du contact"
                        name="phone"
                        control={control}
                        placeholder="+33 1 23 45 67 88"
                        disabled={disabled}
                      />
                      <InputPhone
                        label="Portable du contact"
                        name="phone_port"
                        control={control}
                        placeholder="+33 6 23 45 67 88"
                        disabled={disabled}
                      />
                      <InputText
                        label="Email du contact / login"
                        name="email"
                        type="email"
                        inputMode="email"
                        control={control}
                        disabled={disabled}
                        required
                      />
                    </T.BlockResponsive>
                    <A.Title as="h2" fontSize="24" mb="20" mt="50" fw="black">
                      Mon entreprise expose sur le Pavillon France *
                    </A.Title>
                    <A.Line mb="20" />
                    <T.BlockResponsive>
                      <InputRadio
                        name="stand_type"
                        control={control}
                        disabled={disabled}
                        required
                        radios={[
                          {
                            value: 'individuel',
                            label:
                              'Sur un stand individuel ou partagé (avec Business France)',
                          },
                          {
                            value: 'collectif',
                            label:
                              'Sur un stand collectif régional ou interprofessionnel',
                          },
                        ]}
                      />

                      <InputSelect
                        name="collectif_type"
                        control={control}
                        options={codifCollectif?.map(
                          ({ topicCode, label }) => ({
                            value: topicCode,
                            label,
                          })
                        )}
                        disabled={disabled || stand_type !== 'collectif'}
                        required
                      />
                    </T.BlockResponsive>
                    <A.Title as="h2" fontSize="24" mb="20" mt="50" fw="black">
                      Informations sur votre entreprise
                      <A.Line mt="20" />
                    </A.Title>
                    <T.BlockResponsive>
                      <InputText
                        label="Raison sociale"
                        name="raison_sociale"
                        type="text"
                        control={control}
                        required
                        disabled={disabled}
                      />
                      <InputText
                        label="Nom Commercial"
                        name="nom_societe"
                        information="Tel que vous voulez le voir apparaitre sur les différentes communications"
                        type="text"
                        control={control}
                        required
                        disabled={disabled}
                      />

                      <InputText
                        label="Adresse postale"
                        name="adresse_postale"
                        type="text"
                        control={control}
                        disabled={disabled}
                        required
                      />
                      <InputText
                        label="Code postal"
                        name="code_postal"
                        type="text"
                        inputMode="numeric"
                        control={control}
                        disabled={disabled}
                        required
                      />
                      <InputText
                        label="Ville"
                        name="ville"
                        type="text"
                        control={control}
                        disabled={disabled}
                        required
                      />
                      <InputText
                        label="Pays"
                        name="pays"
                        type="text"
                        control={control}
                        disabled={disabled}
                        placeholder="France"
                        required
                      />
                      <InputPhone
                        label="Téléphone général"
                        name="tel_societe"
                        control={control}
                        placeholder="+33 6 23 45 67 88"
                        disabled={disabled}
                      />
                      <InputText
                        label="E-mail général de la société"
                        name="email_societe"
                        type="email"
                        inputMode="email"
                        control={control}
                        disabled={disabled}
                      />
                      <InputText
                        label="Site Web"
                        name="site_web"
                        type="text"
                        control={control}
                        disabled={disabled}
                        placeholder="https://"
                        required
                      />
                      <InputSelect
                        label="Type d'entreprise"
                        name="type_entreprise"
                        control={control}
                        options={codifTypeEntreprise?.map(
                          ({ topicCode, label }) => ({
                            value: topicCode,
                            label,
                          })
                        )}
                        disabled={disabled}
                        required
                      />
                      <InputText
                        label="Siret"
                        name="siret"
                        type="text"
                        control={control}
                        disabled={disabled}
                        required
                      />

                      <InputNumber
                        label="Taille du domaine"
                        name="taille_domaine"
                        control={control}
                        disabled={disabled}
                        required
                        min={0}
                        max={100_000}
                        information="(en ha entre 0 et 100 000)"
                      />

                      <InputNumber
                        label="Production annuelle"
                        name="production_annuelle"
                        control={control}
                        disabled={disabled}
                        required
                        min={0}
                        max={10_000_000}
                        information="(en nombre de bouteilles entre 0 et 10 000 000)"
                      />

                      <InputSelect
                        label="Pourcentage d’export"
                        name="pourcentage_export"
                        control={control}
                        options={codifPourcentage?.map(
                          ({ topicCode, label }) => ({
                            value: topicCode,
                            label,
                          })
                        )}
                        disabled={disabled}
                      />

                      <InputAutocomplete
                        label="Vos marchés à l’export (plusieurs choix possibles)"
                        name="pays_export"
                        control={control}
                        disabled={disabled}
                        options={codifPays?.map(({ topicCode, label }) => ({
                          value: topicCode,
                          label,
                        }))}
                      />
                      <InputAutocomplete
                        label="Souhaits de développement à l’export ? (plusieurs choix possibles)"
                        name="pays_import"
                        control={control}
                        disabled={disabled}
                        options={codifPays?.map(({ topicCode, label }) => ({
                          value: topicCode,
                          label,
                        }))}
                      />
                      <InputTextarea
                        label="Présentation de la société en français (500 caractères maximum)"
                        name="description_fr"
                        control={control}
                        disabled={disabled}
                        rows={5}
                        counter={500}
                        required
                      />
                      <InputTextarea
                        label="Présentation de la société en anglais (500 caractères maximum)"
                        name="description_en"
                        control={control}
                        disabled={disabled}
                        rows={5}
                        counter={500}
                        required
                      />

                      <InputMedias
                        label="Logo web"
                        information="(jpg, png, 5Mo maximum)"
                        name="logo"
                        control={control}
                        onInit={async (mediaId) => {
                          return await mediaRepository
                            .get(mediaId)
                            .then(({ id, w640, filename }) => ({
                              id,
                              src: w640,
                              filename,
                            }));
                        }}
                        onChange={async (file) => {
                          const formdata = new FormData();
                          formdata.append('media', file);
                          return await mediaRepository
                            .upload(formdata)
                            .then(({ id, w640, filename }) => ({
                              id,
                              src: w640,
                              filename,
                            }));
                        }}
                        onRemove={async (mediaId) => {
                          return await mediaRepository
                            .remove('logo', mediaId)
                            .then((e) => console.log(e));
                        }}
                        onZoom={(src) => {
                          setModalContent(
                            <img src={src} style={{ maxWidth: '100%' }} />
                          );
                        }}
                        disabled={disabled}
                        required
                      />

                      <InputFile
                        label="Logo HD"
                        information="(pdf,eps,ai : 10Mo maximum)"
                        name="logo_hd"
                        control={control}
                        onInit={async (mediaId) => {
                          return await mediaRepository
                            .get(mediaId)
                            .then(({ filename }) => filename);
                        }}
                        onChange={async (file) => {
                          const formdata = new FormData();
                          formdata.append('file', file);
                          return await mediaRepository
                            .uploadFile(formdata)
                            .then(({ id }) => id);
                        }}
                        onRemove={async (mediaId) => {
                          return await mediaRepository
                            .remove('logo', mediaId)
                            .then((e) => console.log(e));
                        }}
                        disabled={disabled}
                        accept="application/pdf, image/*"
                        maxFileSize={10}
                      />
                      <InputText
                        label="Twitter"
                        name="twitter"
                        type="text"
                        control={control}
                        disabled={disabled}
                      />
                      <InputText
                        label="Linkedin"
                        name="linkedin"
                        type="text"
                        control={control}
                        disabled={disabled}
                      />
                      <InputText
                        label="Instagram"
                        name="instagram"
                        type="text"
                        control={control}
                        disabled={disabled}
                      />
                      <InputText
                        label="Facebook"
                        name="facebook"
                        type="text"
                        control={control}
                        disabled={disabled}
                      />
                    </T.BlockResponsive>
                    <A.Title as="h2" fontSize="24" mb="20" mt="50" fw="black">
                      Galerie
                      <A.Line mt="20" />
                    </A.Title>
                    <T.BlockResponsive>
                      <T.Block mb="20">
                        Photos de l’entreprise
                        <A.Text color="grey-50">
                          (5 items, jpg, png, 5Mo maximum)
                        </A.Text>
                      </T.Block>
                      <T.Flex gap={1} wrap>
                        {mediaList.map((name) => (
                          <InputMedias
                            key={name}
                            name={name}
                            control={control}
                            onInit={async (mediaId) => {
                              return await mediaRepository
                                .get(mediaId)
                                .then(({ id, w640, filename }) => ({
                                  id,
                                  src: w640,
                                  filename,
                                }));
                            }}
                            onChange={async (file) => {
                              const formdata = new FormData();
                              formdata.append('media', file);
                              return await mediaRepository
                                .upload(formdata)
                                .then(({ id, w640, filename }) => ({
                                  id,
                                  src: w640,
                                  filename,
                                }));
                            }}
                            onRemove={async (mediaId) => {
                              return await mediaRepository
                                .remove(name, mediaId)
                                .then((e) => console.log(e));
                            }}
                            onZoom={(src) => {
                              setModalContent(
                                <img src={src} style={{ maxWidth: '100%' }} />
                              );
                            }}
                            disabled={disabled}
                          />
                        ))}
                      </T.Flex>
                      <InputText
                        label="Lien vidéo Youtube de l’entreprise"
                        name="youtube_video"
                        type="text"
                        control={control}
                        disabled={disabled}
                      />
                    </T.BlockResponsive>
                    <A.Title as="h2" fontSize="24" mb="20" mt="50" fw="black">
                      Contact{' '}
                      <A.Text as="span" fontSize="14" fw="normal">
                        (Information visible sur les supports de communication)
                      </A.Text>
                    </A.Title>
                    <A.Line mb="20" />
                    <T.BlockResponsive>
                      <InputText
                        label="Prenom"
                        name="contact_revendeur_firstname"
                        type="text"
                        control={control}
                        disabled={disabled}
                      />
                      <InputText
                        label="Nom"
                        name="contact_revendeur_lastname"
                        type="text"
                        control={control}
                        disabled={disabled}
                      />

                      <InputPhone
                        label="Téléphone portable"
                        name="contact_revendeur_port"
                        control={control}
                        placeholder="+33 6 23 45 67 88"
                        disabled={disabled}
                      />

                      <InputPhone
                        label="Téléphone"
                        name="contact_revendeur_tel"
                        control={control}
                        placeholder="+33 1 23 45 67 88"
                        disabled={disabled}
                      />

                      <InputText
                        label="Email"
                        name="contact_revendeur_email"
                        type="email"
                        inputMode="email"
                        control={control}
                        disabled={disabled}
                      />
                    </T.BlockResponsive>
                    <A.Title as="h2" fontSize="24" mb="20" mt="50" fw="black">
                      Vos produits
                      <A.Line mt="20" />
                    </A.Title>
                    <T.BlockResponsive pb="50">
                      <T.Block mb="20">
                        <A.Text color="grey-50">
                          Possibilité de présenter jusqu’à 10 références
                        </A.Text>
                      </T.Block>

                      <T.Flex gap={1} wrap>
                        {products?.map(({ id, photo_1 }, index: number) => (
                          <CustomLink
                            key={id}
                            to={
                              isAdmin
                                ? `/exhibitor/${
                                    response?.exhibitor.id ?? 0
                                  }/product/${id}`
                                : `/product/${id}`
                            }
                            textDecoration="none"
                            color="grey-70"
                            fontSize="14"
                            mb="10"
                          >
                            <T.Flex
                              valign={'center'}
                              halign="center"
                              borderColor="grey-30"
                              borderWidth="1"
                              borderRadius="10"
                              borderStyle="solid"
                              p="10"
                              w="button"
                              h="button"
                              gap=".5"
                              overflow="hidden"
                            >
                              {photo_1 ? (
                                <A.Image
                                  src={photo_1}
                                  position="absolute"
                                  width="100"
                                  height="100"
                                  objectFit="cover"
                                />
                              ) : (
                                <A.Image
                                  src="/bottle.png"
                                  position="absolute"
                                  width="100"
                                  height="100"
                                  objectFit="contain"
                                />
                              )}
                            </T.Flex>
                            <T.Flex halign={'space-between'}>
                              Produit {index + 1}
                              <A.Text textDecoration="underline">Édit.</A.Text>
                            </T.Flex>
                          </CustomLink>
                        ))}
                        {!disabled && products && products?.length < 10 && (
                          <CustomLink
                            to={
                              isAdmin
                                ? `/exhibitor/${
                                    response?.exhibitor.id ?? 0
                                  }/product`
                                : `/product`
                            }
                            textDecoration="none"
                            color="grey-70"
                            fontSize="14"
                          >
                            <T.Flex
                              valign={'center'}
                              halign="center"
                              borderColor="grey-30"
                              borderWidth="1"
                              borderRadius="10"
                              borderStyle="solid"
                              p="10"
                              w="button"
                              h="button"
                              gap=".5"
                            >
                              <A.Icon icon="plus-circle" />
                              <span>Ajouter un produit</span>
                            </T.Flex>
                          </CustomLink>
                        )}
                      </T.Flex>
                    </T.BlockResponsive>
                    <InputCheckbox
                      name="rgpd"
                      control={control}
                      disabled={disabled}
                      label=" Les informations à caractère personnel recueillies font
                      l’objet d’un traitement par Business France. Elles sont
                      nécessaires à l’organisation de l’évènement ou pour vous
                      adresser tout document et/ou service. Business France (ou
                      l’un des membres fondateurs de Team France Export : les
                      Régions, les Chambres de Commerce et d’Industrie et
                      Bpifrance) pourront utiliser ces données afin de vous
                      proposer des produits et/ou services utiles au
                      développement de votre activité. Pour l’exercice de vos
                      droits ou si vous ne souhaitez pas obtenir de propositions
                      de Business France concernant ses actions, prestations,
                      évènements, nous vous invitons à cliquer ici. Pour en
                      savoir plus : Charte de protection des données
                      personnelles."
                    />
                    <A.Alert alert="success" side="🎉" mb="15">
                      {successForm && 'Form submitted successfully !'}
                    </A.Alert>
                    <A.Alert alert="error" side="⚠️" mb="15">
                      {errorForm && <A.InnerHTML html={errorForm} />}
                    </A.Alert>

                    <T.Flex gap={1} wrap>
                      {(!response?.exhibitor.locked || isAdmin) && (
                        <div>
                          <A.Button type="submit" loading={loadingForm}>
                            Enregistrer*
                          </A.Button>
                        </div>
                      )}
                      <div>
                        <A.Button
                          color="black"
                          type="button"
                          bg="grey-light"
                          iconBefore="lock-fill"
                          loading={loadingForm}
                          onClick={async () => {
                            setValue('locked', 1);
                            handleSubmit(() => onSubmit(getValues()))();
                          }}
                          disabled={!!response?.exhibitor.locked}
                        >
                          {response?.exhibitor.locked
                            ? 'Verrouillé'
                            : 'Enregistrer et transmettre**'}
                        </A.Button>
                      </div>
                    </T.Flex>
                    {!response?.exhibitor.locked && (
                      <>
                        <A.Text fontSize="14">
                          *Possibilité de vous reconnecter et séquencer la
                          saisie des informations.
                        </A.Text>
                        <A.Text fontSize="14">
                          **Votre saisie est finalisée, les éléments ne peuvent
                          plus être modifiés.
                        </A.Text>
                      </>
                    )}
                  </T.Block>
                </form>
              </T.Block>
            </T.Block>
          </M.Loading>
        </T.Container>
      </div>
      {pathname !== '/' && !isAdmin && (
        <div className={s.Exhibitor__outlet}>
          <Link className={s.Exhibitor__outlet__close} to="/">
            <div>
              <A.Icon icon="x-lg" />
            </div>
          </Link>
          <div className={s.Exhibitor__outlet__content}>
            <Outlet />
          </div>
        </div>
      )}
    </div>
  );
}

export default Exhibitor;
