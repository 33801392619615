import React, { useState, useMemo } from 'react';
import { CSVLink } from 'react-csv';
import useApiContext from 'hooks/useApiContext';
import useModalContext from 'hooks/useModalContext';
import { useLocation, Outlet, Link } from 'react-router-dom';
import { format, isAfter } from 'date-fns';
import useRepo from 'hooks/useRepo';
import { T, O, M, A } from 'TOMA';
import s from './ExhibitorList.module.scss';

import {
  PaginationState,
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  getPaginationRowModel,
  getExpandedRowModel,
  getSortedRowModel,
  SortingState,
  useReactTable,
} from '@tanstack/react-table';
import { Switch } from 'components/Switch';
import { CustomLink } from 'components/CustomLink';
import { InputStand } from 'components/InputStand';
import Extraction from 'components/Extraction/Extraction';

const columnHelper = createColumnHelper<any>();

function ExhibitorList(): JSX.Element {
  const [csvError, setCsvError] = useState<string | undefined>(undefined);
  const [csvSuccess, setCsvSuccess] = useState<boolean>(false);
  const [csvLoading, setCsvLoading] = useState<boolean>(false);
  const { setModalContent, setModalVisible } = useModalContext();
  const { pathname } = useLocation();
  const [sorting, setSorting] = React.useState<SortingState>([]);
  const {
    userRepository,
    exhibitorRepository,
    codificationRepository,
    preferenceRepository,
  } = useApiContext();
  const [filterCollectif, setFilterCollectif] = useState<any>();
  const [filterName, setFilterName] = useState<any>();
  const [reload, setReload] = useState<number>(0);

  const {
    response = [],
    loading,
    error,
  } = useRepo(async () => await exhibitorRepository.getExhibitors(), [reload]);

  const { response: dateLimit } = useRepo(
    async () => await preferenceRepository.find('date_limit'),
    [reload]
  );

  const { response: codifCollectif } = useRepo(
    async () => await codificationRepository.get('collectif')
  );

  // const [{ pageIndex, pageSize }, setPagination] =
  //   React.useState<PaginationState>({
  //     pageIndex: 0,
  //     pageSize: 10,
  //   });

  // const pagination = React.useMemo(
  //   () => ({
  //     pageIndex,
  //     pageSize,
  //   }),
  //   [pageIndex, pageSize]
  // );

  const columns = useMemo(
    () => [
      columnHelper.accessor('remove', {
        header: '',
        enableSorting: false,
        cell: (info) => {
          const val = info.getValue();
          return (
            <button
              key={val.exhibId}
              type="button"
              className={s.ExhibitorList__remove}
              onClick={() => {
                setModalContent(
                  <div>
                    <A.Text fontSize="22" fw="bold" mb="20">
                      Supprimer l&apos;exposant &quot;{val.exhibName}&quot; ?
                    </A.Text>
                    <T.Flex gap={2} valign="center" mb="-15">
                      <A.Button
                        bg="alert"
                        onClick={() => {
                          userRepository.remove(val.userId).then(() => {
                            setModalVisible(false);
                            info.row.toggleSelected();
                          });
                        }}
                      >
                        Confirmer
                      </A.Button>
                      <A.Button
                        appearance="link"
                        color="grey-80"
                        mb="15"
                        textDecoration="underline"
                        onClick={() => setModalVisible(false)}
                      >
                        Annuler
                      </A.Button>
                    </T.Flex>
                  </div>
                );
              }}
            >
              <A.Icon icon="trash" color="alert" fontSize="22" />
            </button>
          );
        },
      }),
      columnHelper.accessor('new', {
        header: 'New',
        cell: (info) => {
          const { isNew } = info.getValue();
          return (
            <div className={s.ExhibitorList__status}>{isNew ? '🔴' : ''}</div>
          );
        },
      }),
      columnHelper.accessor('status', {
        header: 'Status',
        cell: (info) => {
          const val = info.getValue();
          return (
            <div className={s.ExhibitorList__status} data-key={val}>
              {val}
            </div>
          );
        },
      }),
      columnHelper.accessor('number', {
        header: 'Number',
      }),
      columnHelper.accessor('active', {
        header: 'Activé',
        enableSorting: false,
        cell: ({ getValue }) => {
          const { value, userId, exhibId } = getValue();
          return (
            <Switch
              key={exhibId}
              defaultChecked={value}
              onChange={async (e) =>
                await userRepository.updateUserActive(e, userId)
              }
            />
          );
        },
      }),
      columnHelper.accessor('locked', {
        header: 'Verrouillé',
        enableSorting: false,
        cell: ({ getValue }) => {
          const { value, exhibId } = getValue();
          return (
            <Switch
              key={exhibId}
              checked={value}
              onChange={async (status) =>
                await exhibitorRepository
                  .mergeUserExhibitor('locked', status, exhibId)
                  .finally(() => setReload((prev) => prev + 1))
              }
            />
          );
        },
      }),
      columnHelper.accessor('visibility', {
        header: 'Visible',
        enableSorting: false,
        cell: ({ getValue }) => {
          const { value, exhibId } = getValue();
          return (
            <Switch
              key={exhibId}
              checked={value}
              onChange={async (e) =>
                await exhibitorRepository.mergeUserExhibitor(
                  'visibility',
                  e,
                  exhibId
                )
              }
            />
          );
        },
      }),
      columnHelper.accessor('showroom', {
        header: 'Showroom',
        enableSorting: false,
        cell: ({ getValue }) => {
          const { value, exhibId } = getValue();
          return (
            <Switch
              key={exhibId}
              defaultChecked={value}
              onChange={async (e) =>
                await exhibitorRepository.mergeUserExhibitor(
                  'showroom',
                  e,
                  exhibId
                )
              }
            />
          );
        },
      }),
      columnHelper.accessor('raisonSocial', {
        header: 'Raison sociale',
      }),
      columnHelper.accessor('nomCommercial', {
        header: 'Nom commercial',
      }),
      columnHelper.accessor('collectif', {
        header: 'Participation',
        cell: (info) => (
          <div className={s.ExhibitorList__collectif}>{info.renderValue()}</div>
        ),
      }),
      columnHelper.accessor('year', {
        header: 'Année',
        cell: (info) => {
          const { year, exhibId } = info.renderValue();
          return (
            <div className={s.ExhibitorList__collectif}>
              <InputStand
                label=""
                defaultValue={year}
                onChange={async (e) =>
                  await exhibitorRepository
                    .mergeUserExhibitor('year', e, exhibId)
                    .finally(() => setReload((r) => r + 1))
                }
              />
            </div>
          );
        },
      }),
      columnHelper.accessor('numeroHall', {
        header: 'Numéro de stand + Hall',
        enableSorting: false,
        cell: (info) => {
          const { stand, hall, exhibId } = info.renderValue();
          return (
            <T.Flex key={exhibId} valign="center" gap="1">
              <InputStand
                label="Stand"
                defaultValue={stand}
                onChange={async (e) =>
                  await exhibitorRepository
                    .mergeUserExhibitor('stand', e, exhibId)
                    .finally(() => setReload((r) => r + 1))
                }
              />
              <InputStand
                label="Hall"
                defaultValue={hall}
                onChange={async (e) =>
                  await exhibitorRepository
                    .mergeUserExhibitor('hall', e, exhibId)
                    .finally(() => setReload((r) => r + 1))
                }
              />
            </T.Flex>
          );
        },
      }),
      columnHelper.accessor('edit', {
        header: '',
        cell: (info) => (
          <CustomLink to={`/exhibitor/${info.renderValue()}`}>
            Éditer
          </CustomLink>
        ),
      }),
    ],
    []
  );

  const data = useMemo(
    () =>
      response
        .filter((e) => {
          if (filterCollectif === 'individuel') {
            return e.exhibitor_stand_type === filterCollectif;
          }
          if (filterCollectif) {
            return e.exhibitor_collectif_type === filterCollectif;
          }
          return true;
        })
        .filter((e) => {
          const regex = new RegExp(filterName, 'gi');
          return filterName
            ? regex.test(e.exhibitor_raison_sociale ?? '') ||
                regex.test(e.exhibitor_nom_societe ?? '')
            : true;
        })
        .map((e) => {
          let collectif = '';
          if (e.exhibitor_stand_type === 'individuel') {
            collectif = 'Individuel';
          } else {
            collectif = e.exhibitor_collectif_type
              ? codifCollectif?.find(
                  (ee) => ee.topicCode === e.exhibitor_collectif_type
                )?.label ?? ''
              : collectif;
          }
          return {
            remove: {
              userId: e.user_id,
              exhibId: e.exhibitor_id,
              exhibName: e.exhibitor_raison_sociale,
            },
            new: {
              isNew: isAfter(new Date(e.user_created_at), new Date(2023, 8, 1)),
            },
            status: e.exhibitor_locked
              ? 'Transmis'
              : e.exhibitor_raison_sociale
              ? 'Enregistré'
              : e.user_email_verified_at
              ? 'En cours'
              : 'En attente',
            active: {
              value: !!e.user_email_verified_at,
              userId: e.user_id,
              exhibId: e.exhibitor_id,
            },
            visibility: {
              value: !!e.exhibitor_visibility,
              exhibId: e.exhibitor_id,
            },
            locked: {
              value: !!e.exhibitor_locked,
              exhibId: e.exhibitor_id,
            },
            id: e.exhibitor_id,
            userEmail: e.user_email,
            number: `ID ${String(e.exhibitor_id).padStart(4, '0')}`,
            showroom: {
              value: !!e.exhibitor_showroom,
              exhibId: e.exhibitor_id,
            },
            raisonSocial: e.exhibitor_raison_sociale,
            nomCommercial: e.exhibitor_nom_societe,
            collectif,
            year: { year: e.year, exhibId: e.exhibitor_id },
            numeroHall: {
              stand: e.exhibitor_stand,
              hall: e.exhibitor_hall,
              exhibId: e.exhibitor_id,
            },
            edit: e.exhibitor_id,
          };
        }),
    [response, codifCollectif, filterCollectif, filterName]
  );

  const table = useReactTable({
    // data: data.slice(pageIndex * pageSize, (pageIndex + 1) * pageSize),
    data,
    columns,
    state: {
      sorting,
      // pagination,
    },
    // pageCount: Math.ceil(data.length / pageSize) || 1,
    manualPagination: true,
    // onPaginationChange: setPagination,
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getExpandedRowModel: getExpandedRowModel(),
    // getPaginationRowModel: getPaginationRowModel(),
  });

  return (
    <div>
      <div className={pathname !== '/' ? s.blur : undefined}>
        <T.Container py="50">
          <M.Loading loading={loading} error={error} fullWiewport>
            <A.Title
              as="h1"
              fontSize="48"
              mb="30"
              fw="black"
              textAlign="center"
            >
              List of exhibitors
            </A.Title>

            <T.Block mw="small" mb="10">
              <A.InputDate
                value={dateLimit ? new Date(dateLimit) : undefined}
                name="test"
                label="Date limite"
                onChange={async (data) => {
                  await preferenceRepository.update(
                    'date_limit',
                    format(data, 'yyyy-MM-dd')
                  );
                }}
                mb="20"
              />
            </T.Block>
            <T.Flex gap={2} mb="50">
              <div>
                <A.Button
                  bg="alert"
                  onClick={() => {
                    setModalContent(
                      <div>
                        <A.Text fontSize="22" fw="bold" mb="20">
                          Confirmez ?
                        </A.Text>
                        <T.Flex gap={2} valign="center" mb="-15">
                          <A.Button
                            bg="alert"
                            onClick={async () => {
                              await exhibitorRepository.lockAllExhibitors(
                                false
                              );
                              setReload((e) => e + 1);
                              setModalVisible(false);
                            }}
                          >
                            Confirmer
                          </A.Button>
                          <A.Button
                            appearance="link"
                            color="grey-80"
                            mb="15"
                            textDecoration="underline"
                            onClick={() => setModalVisible(false)}
                          >
                            Annuler
                          </A.Button>
                        </T.Flex>
                      </div>
                    );
                  }}
                >
                  Tout déverrouiller
                </A.Button>
              </div>
              <div>
                <A.Button
                  bg="success"
                  onClick={() => {
                    setModalContent(
                      <div>
                        <A.Text fontSize="22" fw="bold" mb="20">
                          Confirmez ?
                        </A.Text>
                        <T.Flex gap={2} valign="center" mb="-15">
                          <A.Button
                            bg="alert"
                            onClick={async () => {
                              await exhibitorRepository.lockAllExhibitors(true);
                              setReload((e) => e + 1);
                              setModalVisible(false);
                            }}
                          >
                            Confirmer
                          </A.Button>
                          <A.Button
                            appearance="link"
                            color="grey-80"
                            mb="15"
                            textDecoration="underline"
                            onClick={() => setModalVisible(false)}
                          >
                            Annuler
                          </A.Button>
                        </T.Flex>
                      </div>
                    );
                  }}
                >
                  Tout verrouiller
                </A.Button>
              </div>
            </T.Flex>

            <T.Flex gap={2} mb="50">
              <div>
                <A.Button
                  bg="alert"
                  onClick={() => {
                    setModalContent(
                      <div>
                        <A.Text fontSize="22" fw="bold" mb="20">
                          Confirmez ?
                        </A.Text>
                        <T.Flex gap={2} valign="center" mb="-15">
                          <A.Button
                            bg="alert"
                            onClick={async () => {
                              await exhibitorRepository.visibleAllExhibitors(
                                false
                              );
                              setReload((e) => e + 1);
                              setModalVisible(false);
                            }}
                          >
                            Confirmer
                          </A.Button>
                          <A.Button
                            appearance="link"
                            color="grey-80"
                            mb="15"
                            textDecoration="underline"
                            onClick={() => setModalVisible(false)}
                          >
                            Annuler
                          </A.Button>
                        </T.Flex>
                      </div>
                    );
                  }}
                >
                  Tous invisible
                </A.Button>
              </div>
              <div>
                <A.Button
                  bg="success"
                  onClick={() => {
                    setModalContent(
                      <div>
                        <A.Text fontSize="22" fw="bold" mb="20">
                          Confirmez ?
                        </A.Text>
                        <T.Flex gap={2} valign="center" mb="-15">
                          <A.Button
                            bg="alert"
                            onClick={async () => {
                              await exhibitorRepository.visibleAllExhibitors(
                                true
                              );
                              setReload((e) => e + 1);
                              setModalVisible(false);
                            }}
                          >
                            Confirmer
                          </A.Button>
                          <A.Button
                            appearance="link"
                            color="grey-80"
                            mb="15"
                            textDecoration="underline"
                            onClick={() => setModalVisible(false)}
                          >
                            Annuler
                          </A.Button>
                        </T.Flex>
                      </div>
                    );
                  }}
                >
                  Tous visible
                </A.Button>
              </div>
            </T.Flex>

            <T.Flex valign="center" bg="grey-10" p="30" pb="10" mb="40" gap={2}>
              <A.Text mb="15">Filtrer :</A.Text>
              <T.Flex valign="center" gap="2">
                <A.InputText
                  name="search"
                  type="text"
                  placeholder="Exhibitors"
                  onChange={(e) => {
                    // setPagination((state) => ({
                    //   ...state,
                    //   pageIndex: 0,
                    // }));
                    setFilterName(e);
                  }}
                />
              </T.Flex>
              <T.Flex valign="center" gap="2">
                <T.Block w="small">
                  <A.InputSelect
                    name="sort"
                    value={filterCollectif}
                    placeholder="Participation"
                    options={[
                      { value: '', label: 'Aucun filtre' },
                      { value: 'individuel', label: 'Individuel' },
                      ...(codifCollectif?.map((e) => ({
                        value: e.topicCode,
                        label: e.label,
                      })) ?? []),
                    ]}
                    onChange={({ target }) => {
                      // setPagination((state) => ({
                      //   ...state,
                      //   pageIndex: 0,
                      // }));
                      setFilterCollectif(target.value);
                    }}
                  />
                </T.Block>
              </T.Flex>
              <CSVLink
                style={{ textDecoration: 'none' }}
                filename={`export${
                  filterCollectif ? `-${filterCollectif}` : ''
                }-${format(new Date(), 'dd-MM-yyyy-HH-mm-ss')}.csv`}
                separator={';'}
                enclosingCharacter={'"'}
                data={[
                  [
                    'Status',
                    'ID',
                    'Contact pour ce dossier',
                    'Raison social',
                    'Nom commercial',
                    'Participation',
                    'Year',
                    'Stand',
                    'Hall',
                  ],
                  ...data.map((e) => {
                    return [
                      e.status.replace(/"/g, '""'),
                      e.id,
                      e.userEmail,
                      e.raisonSocial?.replace(/"/g, '""'),
                      e.nomCommercial?.replace(/"/g, '""'),
                      e.collectif,
                      e.year.year,
                      e.numeroHall.stand,
                      e.numeroHall.hall,
                    ];
                  }),
                ]}
              >
                <T.Block px="40" py="10" bg="primary" borderRadius="5" mb="15">
                  <A.Text color="white" textDecoration="none">
                    Extraction simple
                  </A.Text>
                </T.Block>
              </CSVLink>

              <A.InputFile
                name="csv"
                onChange={async ({ target }) => {
                  setCsvLoading(true);
                  setCsvSuccess(false);
                  setCsvError('');
                  if (!target.files) return;
                  const file = target.files[0];
                  const formdata = new FormData();
                  formdata.append('file', file);
                  exhibitorRepository
                    .uploadCSV(formdata)
                    .then(() => {
                      setCsvSuccess(true);
                    })
                    .catch((err: any) => {
                      setCsvError(`${err.message}`);
                    })
                    .finally(() => {
                      setCsvLoading(false);
                    });
                }}
                accept=".csv"
                buttonLabel="Upload CSV"
                onlyButton
                loading={csvLoading}
                error={csvError}
                success={
                  csvSuccess ? (
                    <span>La base de donnée a été modifiée. 🎉</span>
                  ) : null
                }
              />
              <Extraction
                name={filterCollectif}
                ids={data
                  .filter((e) => e.year.year === '2024')
                  .map((e) => e.id)}
              >
                Commencer l&apos;extraction complète
              </Extraction>
            </T.Flex>
            <T.Block mb="20">
              <table className={s.Table}>
                <thead>
                  {table.getHeaderGroups().map((headerGroup) => (
                    <tr key={headerGroup.id}>
                      {headerGroup.headers.map((header) => (
                        <th key={header.id}>
                          <A.Button
                            appearance="link"
                            color="grey-80"
                            onClick={header.column.getToggleSortingHandler()}
                          >
                            {header.isPlaceholder
                              ? null
                              : flexRender(
                                  header.column.columnDef.header,
                                  header.getContext()
                                )}

                            {{
                              asc: <A.Icon icon="chevron-down" ml="5" />,
                              desc: <A.Icon icon="chevron-up" ml="5" />,
                            }[header.column.getIsSorted() as string] ?? null}
                          </A.Button>
                        </th>
                      ))}
                    </tr>
                  ))}
                </thead>
                <tbody>
                  {table.getRowModel().rows.map((row) => {
                    return (
                      <tr
                        key={row.id}
                        className={s.ExhibitorList__row}
                        data-removed={row.getIsSelected() ? 'true' : undefined}
                      >
                        {row.getVisibleCells().map((cell) => (
                          <td key={cell.id}>
                            <div className={s.ExhibitorList__row__div}>
                              {flexRender(
                                cell.column.columnDef.cell,
                                cell.getContext()
                              )}
                            </div>
                          </td>
                        ))}
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </T.Block>

            {/* <T.Flex gap="1" valign={'center'}>
              <A.Button
                appearance="small"
                onClick={() => table.setPageIndex(0)}
                disabled={!table.getCanPreviousPage()}
              >
                {'<<'}
              </A.Button>
              <A.Button
                appearance="small"
                onClick={() => table.previousPage()}
                disabled={!table.getCanPreviousPage()}
              >
                {'<'}
              </A.Button>
              <A.Button
                appearance="small"
                onClick={() => table.nextPage()}
                disabled={!table.getCanNextPage()}
              >
                {'>'}
              </A.Button>
              <A.Button
                appearance="small"
                onClick={() => table.setPageIndex(table.getPageCount() - 1)}
                disabled={!table.getCanNextPage()}
              >
                {'>>'}
              </A.Button>
              <div>
                <span>Page </span>
                <strong>
                  {table.getState().pagination.pageIndex + 1} of{' '}
                  {table.getPageCount()}
                </strong>
              </div>
            </T.Flex> */}
          </M.Loading>
        </T.Container>
      </div>
      {pathname !== '/' && (
        <div className={s.ExhibitorList__outlet}>
          <Link
            className={s.ExhibitorList__outlet__close}
            to="/"
            onClick={() => setReload((e) => e + 1)}
          >
            <div>
              <A.Icon icon="x-lg" />
            </div>
          </Link>
          <div className={s.ExhibitorList__outlet__content}>
            <Outlet />
          </div>
        </div>
      )}
    </div>
  );
}

export default ExhibitorList;
